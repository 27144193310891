@import "../../assets/scss/variables.scss";
.firstTimeLoginPage {
	width: 100%;
	position: relative;
	overflow: hidden;
	.error{
		color: $colorRed;
	}
	.wholePageLoader {
		display: flex;
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background: rgba(255, 255, 255, 0.5);
		z-index: 9999;
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.innerPage {
		width: 100%;
		height: 100%;
		float: left;
		.firstTimeLoginright {
			width: 55%;
			float: left;
			.imageSection {
				width: 100%;
				height: 100%;
			}
		}
		.firstTimeLoginleft {
			width: 45%;
			float: left;
			height: 100vh;
			.formSection {
				display: flex;
				justify-content: center;
				// align-items: center;
				height: 100%;
				padding: 45px 0 20px 0;

				.mainForm {
					width: 100%;
					max-width: 400px;

					.logo {
						padding-bottom: 60px;
						.logoMain {
							width: 242px;
							height: 92px;
							display: inline-block;
						}
						.logoTxt {
							color: $colorYellow;
							font-size: $fontSize16;
							margin-bottom: 0;
						}
					}
					.bigTxtsec {
						display: block;
						margin-bottom: 40px;
						span {
							color: $colorDarkBlue;
							text-align: left;
							font-family: $secondaryFont;
							font-size: $fontSize32;
							line-height: 40px;
							display: block;
							letter-spacing: 1px;
							margin-bottom: 20px;
						}
						.titleText {
							color: $colorGrey;
							font-size: 12px;
							text-align: left;
							margin: 8px 0 0px 0;
						}
					}

					.checkbox-Forgot {
						display: flex;
						justify-content: flex-end;
						padding: 7px 0 20px 0;

						.forgotLink {
							a {
								font-size: $fontSize16;
								color: $colorBlue;
							}
							a:hover {
								color: $colorBlue;
							}
						}
					}
				}
				.bottomLink {
					display: block;
					margin-top: 100px;
					p {
						font-size: $fontSize16;
						color: $colorGrey;
					}
					.blueLink {
						cursor: pointer;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.firstTimeLoginPage {
		.innerPage {
			.firstTimeLoginright {
				width: 100%;
			}
			.firstTimeLoginleft {
				width: 100%;
				position: absolute !important;
				z-index: 9;

				.formSection {
					align-items: center;
					padding: 0;
					.mainForm {
						max-width: 496px;
						background: $bgWhite;
						padding: 40px 76px;
						box-shadow: 0px 2px 12px -6px $colorBlack;
					}
				}
			}
		}
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.firstTimeLoginPage {
		.innerPage {
			.firstTimeLoginright {
				width: 100%;
			}
			.firstTimeLoginleft {
				width: 100%;
				position: absolute !important;
				z-index: 9;
				
				.formSection{
					align-items: center;
					padding: 0;
					&:not(.err){
						height: auto !important;
					}
					.mainForm {
						max-width: 496px;
						background: $bgWhite;
						padding: 40px 76px;
						box-shadow: 0px 2px 12px -6px $colorBlack;
					}
				}
			}
		}
	}
}
@media (min-width: 320px) and (max-width: 480px) {
	.firstTimeLoginPage {
		.innerPage {
			.firstTimeLoginright {
				width: 100%;
			}
			.firstTimeLoginleft {
				width: 100%;
				position: absolute !important;
				z-index: 9;

				.formSection {
					align-items: center;
					padding: 0;
					&:not(.err){
						height: auto !important;
					}
					.mainForm {
						max-width: 340px;
						background: $bgWhite;
						padding: 28px 20px;
						box-shadow: 0px 2px 12px -6px $colorBlack;

						.logo {
							padding-bottom: 25px;
							.logoMain {
								height: 60px;
								img {
									height: 100%;
									width: 100%;
									object-fit: contain;
								}
							}
							.logoTxt {
								font-size: $fontSize15;
							}
						}
						.bigTxtsec {
							span {
								margin-bottom: 0px;
								font-size: $fontSize28;
							}
							margin-bottom: 25px;
						}
						.textField {
							margin-bottom: 20px;
						}
						.checkbox-Forgot{
							.forgotLink{
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (device-width: 375px)and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
	.checkbox-Forgot{
		.forgotLink{
			display: none;
		}
	}
 }
 @media only screen  and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) { 
	.checkbox-Forgot{
		.forgotLink{
			display: none;
		}
	}
 }
 @media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) { 
	 .checkbox-Forgot{
							.forgotLink{
								display: none;
							}
						}}
 @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) { 
	 .checkbox-Forgot{
							.forgotLink{
								display: none;
							}
						}}
 @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
	 .checkbox-Forgot{
							.forgotLink{
								display: none;
							}
						} }
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) { 
	.checkbox-Forgot{
							.forgotLink{
								display: none;
							}
						}}