@import "../../assets/scss/variables.scss";
.loginPage {
	width: 100%;
	position: relative;
	overflow: hidden;
	.wholePageLoader {
		display: flex;
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background: rgba(255, 255, 255, 0.5);
		z-index: 9999;
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.innerPage {
		width: 100%;
		height: 100%;
		float: left;
		.loginright {
			width: 55%;
			float: left;
			.imageSection {
				width: 100%;
				height: 100%;
			}
		}
		.loginleft {
			width: 45%;
			float: left;
			height: 100vh;
			.formSection {
				display: flex;
				justify-content: center;
				// align-items: center;
				height: 100%;
				padding: 45px 0 20px 0;

				.mainForm {
					width: 100%;
					max-width: 400px;

					.logo {
						padding-bottom: 40px;
						.logoMain {
							width: 242px;
							height: 92px;
							display: inline-block;
						}
						.logoTxt {
							color: $colorYellow;
							font-size: $fontSize16;
							margin-bottom: 0;
						}
					}
					.bigTxtsec {
						display: block;
						margin-bottom: 30px;
						span {
							color: $colorDarkBlue;
							text-align: left;
							font-family: $secondaryFont;
							font-size: $fontSize32;
							line-height: 40px;
							display: inline-block;
							letter-spacing: 1px;
						}
						.titleText {
							color: $colorGrey;
							font-size: 12px;
							text-align: left;
							margin: 8px 0 0px 0;
						}
					}

					.checkbox-Forgot {
						flex-direction: row;
						display: flex;
						justify-content: space-between;
						padding: 7px 0 20px 0;

						.checkBox {
							// Checkbox Css
							[type="checkbox"]:not(:checked),
							[type="checkbox"]:checked {
								position: absolute;
								left: -9999px;
							}
							[type="checkbox"]:not(:checked) + label,
							[type="checkbox"]:checked + label {
								position: relative;
								padding-left: 32px;
								cursor: pointer;
							}

							/* checkbox aspect */
							[type="checkbox"]:not(:checked) + label:before,
							[type="checkbox"]:checked + label:before {
								content: "";
								position: absolute;
								left: 0;
								top: 0px;
								width: 24px;
								height: 24px;
								background: $bgLightYellow;
								border-radius: 6px;
							}
							/* checked mark aspect */
							[type="checkbox"]:not(:checked) + label:after,
							[type="checkbox"]:checked + label:after {
								content: "\e914";
								position: absolute;
								top: 8px;
								left: 0.5em;
								font-size: $fontSize12;
								line-height: 0.8;
								color: $bgYellow;
								transition: all 0.2s;
								font-family: "greystone" !important;
							}
							/* checked mark aspect changes */
							[type="checkbox"]:not(:checked) + label:after {
								opacity: 0;
								transform: scale(0);
							}
							[type="checkbox"]:checked + label:after {
								opacity: 1;
								transform: scale(1);
							}
							// Checkbox Css
							label {
								font-size: $fontSize16;
								color: $colorGrey;
							}
						}
						.forgotLink {
							a {
								font-size: $fontSize16;
								color: $colorBlue;
							}
							a:hover {
								color: $colorBlue;
							}
						}
					}
					.btnSection {
						// padding-bottom: 40px;
					}
				}
				.bottomLink {
					display: block;
					margin-top: 100px;

					p {
						font-size: $fontSize16;
						color: $colorGrey;
					}
					.blueLink {
						cursor: pointer;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.loginPage {
		.innerPage {
			.loginright {
				width: 100%;
				// .imageSection {
				// 	position: relative;

				// 	.rightNoswitchSec {
				// 		height: 90%;
				// 	}
				// }
			}
			.loginleft {
				width: 100%;
				position: absolute !important;

				.formSection {
					align-items: center;
					padding: 0;
					.mainForm {
						max-width: 496px;
						background: $bgWhite;
						padding: 40px 76px;
						position: absolute;
						z-index: 9;
						box-shadow: 0px 2px 12px -6px $colorBlack;
					}
				}
			}
		}
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.loginPage {
		.innerPage {
			.loginright {
				width: 100%;
			}
			.loginleft {
				width: 100%;
				position: absolute !important;

				.formSection {
					align-items: center;
					padding: 0;
					.mainForm {
						max-width: 496px;
						background: $bgWhite;
						padding: 40px 76px;
						position: absolute;
						z-index: 9;
						box-shadow: 0px 2px 12px -6px $colorBlack;
					}
				}
			}
		}
	}
}
