@import "../../assets/scss/variables.scss";
.nodataFound {
    height: 100vh;
    display: block;
    .backYellowarea {
        background: #fdfaf4;
        display: block;
        width: 100% !important;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: 100vh;
        z-index: -1;
    }
    .upperLogo {
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

        .mainImg {
            height: 140px;
            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
    }
    .centerPart {
        background: #fdfaf4;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 55%;
        padding: 0px 10px;
        width: 100% !important;
        margin: 0 auto;
        background:#fdfaf4 !important;
        border: 1px solid #feb302;
        position: relative;
        .boldText {
            color: #60872a;
            font-size: $fontSize24;
            margin: 0;
            font-weight: 500;
            padding-bottom: 10px;
            padding-top: 22px;
        }
        .simpleText {
            color: #3d3d3d;
            font-size: $fontSize16;
            margin: 0;
        }
        .imgSec {
            padding-top: 7% !important;
            height: 100% !important;
            position: absolute;
            bottom: 0;    
            display: flex;
            align-items: center;
            justify-content: center;
            width: 350px;
            max-width: 100%;
        }
            a img {
                max-width: inherit !important;
                height: 50px !important;
                display: block !important;
                margin: 0 5px !important;
            
            .link {
                font-weight: 700;
                color: $colorBlack;
                font-size: $fontSize16;
                margin: 0;
                cursor: pointer;
                text-decoration: underline !important;
                padding-left: 6px;
            }
        }
    }
}
@media (max-width: 767px) {
    .nodataFound {
        display: block !important;
        .backYellowarea {
            width: 100%;
        }
        .upperLogo {
            height: auto !important;
            display: block !important;
            align-items: center;
            justify-content: center;

            .mainImg {
                height: 140px;
                img {
                    object-fit: contain;
                    height: 70% !important;
                    width: 100%;
                }
            }
        }
        .centerPart {
            // height: 94%;
            width: 85%;
            .imgSec {
                position: relative !important;
                a {
                    img {
                        width: 145px !important;
                    }
                }
            }
            .boldText {
                font-size: $fontSize22;
            }
            .simpleText {
                font-size: $fontSize16;
            }
            .joggingSec {
                .link {
                    font-size: $fontSize16;
                }
            }
        }
    }
}
