@import "../../assets/scss/variables.scss";
.HomePageListingPage {
	width: 100%;
	position: relative;
	float: left;
	overflow: hidden;
	height: 100%;
	.centerSec {
		width: 100%;
		float: left;
		padding-top: 60px;
		background: $bglightBlue;
		height: 100%;
		.rightMain {
			float: left;
			height: calc(100vh - 140px) !important;
			width: 100%;
			.searchSec {
				background: $bgWhite;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 12px 40px 12px 32px;
				.leftSec {
					display: flex;
					flex-direction: row;
					align-items: center;
					.blackdarkTxt {
						color: $colorDarkBlue;
						font-size: $fontSize18;
						padding-right: 15px;
						font-weight: 500;
					}
					.divider {
						height: 30px;
						width: 2px;
						display: block;
						background: $bgLightGrey;
						margin-right: 15px;
					}
					.greyTxt {
						color: $colorGrey;
						padding-right: 40px;
						font-size: $fontSize16;
					}
					.searchMain {
						width: 260px;
					}
				}
				.rightBtn {
					display: flex;
					.cyanBtn {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px 28px;
						background: $bgCyan;
						border-radius: 15px;
						font-size: $fontSize12;
						color: $colorDarkCyan;
						width: 150px;
						i {
							font-size: $fontSize20;
							margin-right: 8px;
						}
					}
				}
			}
			.homepagelisting {
				margin: 30px 50px 15px 50px;
				background: $bgWhite;
				border-radius: 10px;
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.034);
				padding: 22px 28px;
				.listingTopSec {
					display: flex;
					justify-content: space-between;
					align-items: center;
					// padding-right: 20px;
					.txtSec {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						.blacTxt {
							color: $colorDarkBlue;
							font-size: $fontSize18;
							font-weight: 500;
						}
						.greyTxt {
							color: $colorGrey;
							font-size: $fontSize16;
							text-align: left;
						}
					}
					.btnSec {
						width: 120px;
						.yellowExportBtn {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 10px 22px;
							background: $bgLightDarkYellow;
							border-radius: 20px;
							font-size: $fontSize12;
							color: $colorYellow;
							i {
								font-size: $fontSize16;
								margin-right: 8px;
							}
						}
					}
				}
				.homepageHeader {
					padding: 0px 0px 20px 0px;
					display: block;
					margin-top: 20px;
					border-bottom: 1px solid $colorBorderBlue;
					ul {
						list-style-type: none;
						padding: 0px;
						margin: 0px;
						display: flex;
						align-items: center;
						li {
							text-align: left;
							color: $colorGrey;
							font-size: $fontSize16;
							font-weight: 600;
						}
						.nameHead {
							width: 25%;
							display: flex;
							align-items: center;
							i {
								margin-left: 10px;
								transform: rotate(90deg);
								font-size: 14px;
								color: $colorYellow;
								&.upArrow {
									transform: rotate(270deg);
								}
							}
						}
						.urlHead {
							width: 25%;
						}
						.clubHead {
							width: 20%;
						}
						// .buttonHead {
						// 	width: 18%;
						// }
						.actionsHead {
							width: 10%;
						}
						.toggleHead {
							width: 20%;
							text-align: center;
						}
					}
				}
				.sortDiv {
					display: none;
				}
				.paginationSec {
					height: 42px;
				}
				.homepageScroll {
					height: calc(100vh - 460px) !important;
					// margin: 20px 0px;
					ul {
						display: flex;
						flex-wrap: wrap;
						list-style: none;
						margin: 0;
						padding: 0;

						.homepageRow {
							background-color: white;
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
							align-items: center;
							width: 100%;
							border-bottom: 1px solid $colorBorderBlue;
							padding: 20px 0;

							.blueTxt {
								font-size: $fontSize16;
								font-weight: 500;
								color: $colorDarkBlue;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							.nameImgRow {
								width: 25%;
								display: flex;
								flex-direction: row;
								align-items: center;
								position: relative;
								.imgMain {
									height: 50px;
									width: 50px;
									margin-right: 10px;
									border-radius: 10px;
									.useIMG {
										width: 100%;
										height: 100%;
										object-fit: contain;
										border-radius: 10px;
									}
								}
								.infoSec {
									text-align: left;
									display: flex;
									align-items: flex-start;
									width: 70%;
								}
							}

							.urlRow {
								width: 25%;
								text-align: left;
								display: flex;
								flex-direction: row;
								align-items: center;
							}
							.clubRow {
								width: 20%;
								text-align: left;
								display: flex;
								flex-direction: row;
								align-items: center;
							}
							// .buttonRow {
							// 	width: 18%;
							// 	position: relative;
							// 	padding-left: 3%;
							// 	.colorBox {
							// 		height: 30px;
							// 		width: 30px;
							// 		border-radius: 8px;
							// 		display: block;
							// 	}
							// }
							.actionsRow {
								width: 10%;
								text-align: left;
								display: flex;
								flex-direction: row;
								align-items: center;
								.clickBtn {
									padding: 12px;
									background: $bgPagination;
									border-radius: 12px;
									margin-right: 18px;
									height: 46px;
									width: 46px;
									.tooltipSmall {
										padding: 12px;
									}
									.round {
										display: flex;
										align-items: center;
										justify-content: center;
										padding: 4px 4px;
										border-radius: 40px;
										background: #d8e0e9;
									}

									i {
										color: $colorGrey;
										font-size: $fontSize16;
									}
								}
							}
							.toggleRow {
								width: 20%;
								padding-top: 15px;
								.switch {
									position: relative;
									display: inline-block;
									width: 45px;
									height: 20px;
									background-color: #99adc4;
									border-radius: 20px;
									transition: all 0.3s;
									cursor: pointer;
								}
								.switch::after {
									content: "";
									position: absolute;
									width: 32px;
									height: 32px;
									border-radius: 50%;
									background-color: $lightToggle;
									top: -6px;
									left: 0px;
									transition: all 0.3s;
								}

								.checkbox:checked + .switch::after {
									background-color: $bgYellow;
									left: 16px;
								}
								.checkbox:checked + .switch {
									background-color: $colorLightYellow;
								}
								.checkbox {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1400px) {
	.HomePageListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 140px) !important;
				&.sidePadding {
					.searchSec {
						padding: 12px 22px 12px 22px;
						.leftSec {
							.blackdarkTxt {
								font-size: $fontSize16;
							}
							.greyTxt {
								padding-right: 25px;
							}

							.searchMain {
								width: 200px;
							}
						}
					}
					.homepagelisting {
						.homepageHeader {
							display: none;
						}
						.sortDiv {
							display: flex;
							flex-direction: row;
							margin: 10px 0 0 0;
							align-items: center;
							font-size: $fontSize14;
							.linkSmall {
								margin-left: 8px;
								font-size: $fontSize16;
								color: $colorYellow;
								font-weight: 600;
								.greySmall {
									margin-left: 4px;
									color: $colorGrey;
									font-size: $fontSize14;
								}
								i {
									color: $colorYellow;
									font-size: $fontSize16;
									font-weight: 600;
									transform: rotate(90deg);
									margin-left: 4px;
									&.upArrow {
										transform: rotate(270deg);
									}
								}
							}
						}
						.homepageScroll {
							// height: calc(100vh - 180px);
							ul {
								.homepageRow {
									.nameImgRow {
										width: 50%;
										padding-top: 40px;

										&::before {
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
											content: "NAME";
											position: absolute;
											top: 0px;
										}
									}
									.urlRow {
										width: 50%;
										padding-top: 40px;
										position: relative;
										&::before {
											content: "URL";
											position: absolute;
											top: -10px;
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
										}
									}
									// .buttonRow {
									// 	width: 26%;
									// 	padding-top: 40px;
									// 	padding-left: 8%;
									// 	&::before {
									// 		content: "BUTTON COLOR";
									// 		position: absolute;
									// 		top: -10px;
									// 		left: 0;
									// 		color: $colorGrey;
									// 		font-size: $fontSize16;
									// 		font-weight: 600;
									// 		margin-bottom: 15px;
									// 	}
									// }
									.clubRow {
										width: 33%;
										position: relative;
										padding-top: 60px;
										display: flex;
										// justify-content: center;
										.tooltipBig {
											margin-left: 20%;
										}
										&::before {
											content: "CLUB NAME";
											position: absolute;
											top: 18px;
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
											width: 100%;
											text-align: left;
										}
									}
									.actionsRow {
										width: 26%;
										position: relative;
										padding-top: 50px;
										// margin-left: 6%;
										// .clickBtn {
										// 	height: 40px;
										// 	width: 40px;
										// 	.round {
										// 		padding: 3px 3px;
										// 	}
										// }
										&::before {
											content: "ACTIONS";
											position: absolute;
											top: 15px;
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
										}
									}
									.toggleRow {
										width: 40%;
										position: relative;
										padding-top: 58px;
										display: flex;
										// justify-content: center;
										.tooltipBig {
											margin-left: 20%;
										}
										&::before {
											content: "SHOW/HIDE ON MOBILE";
											position: absolute;
											top: 16px;
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
											width: 100%;
											text-align: left;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
	.HomePageListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 120px) !important;

				&.sidePadding {
					.homepagelisting {
						.homepageHeader {
							display: none;
						}
						.homepageScroll {
							height: calc(100vh - 450px) !important;
						}
					}
				}

				.homepagelisting {
					margin: 30px 20px 15px 20px;
					padding: 16px 12px;
					.homepageHeader {
						ul {
							.nameHead {
								width: 22%;
							}
							.urlHead {
								width: 25%;
							}
							.buttonHead {
								width: 18%;
							}
							.clubHead {
								width: 18%;
							}
							.actionsHead {
								width: 11%;
							}
							.toggleHead {
								width: 24%;
							}
						}
					}

					.homepageScroll {
						height: calc(100vh - 480px) !important;
						ul {
							.homepageRow {
								.nameImgRow {
									width: 22%;
								}
								.urlRow {
									width: 24%;
								}
								// .buttonRow {
								// 	width: 17%;
								// }
								.clubRow {
									width: 18%;
								}
								.actionsRow {
									width: 12%;
									.clickBtn {
										height: 40px;
										width: 40px;
										.round {
											padding: 3px 3px;
										}
									}
								}
								.toggleRow {
									width: 23%;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
	.HomePageListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 180px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
				}
				.homepagelisting {
					margin: 30px 20px 15px 14px;
					padding: 16px 12px;
					.homepageHeader {
						ul {
							.nameHead {
								width: 25%;
							}
							.urlHead {
								width: 25%;
							}
							// .buttonHead {
							// 	width: 18%;
							// }
							.clubHead {
								width: 20%;
							}
							.actionsHead {
								width: 10%;
							}
							.toggleHead {
								width: 20%;
							}
						}
					}

					.homepageScroll {
						height: calc(100vh - 370px) !important;

						ul {
							.homepageRow {
								.nameImgRow {
									width: 25%;
								}
								.urlRow {
									width: 25%;
								}
								// .buttonRow {
								// 	width: 18%;
								// }
								.clubRow {
									width: 19%;
								}
								.actionsRow {
									width: 10%;
									.clickBtn {
										height: 40px;
										width: 40px;
										.round {
											padding: 3px 3px;
										}
									}
								}
								.toggleRow {
									width: 20%;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
	.HomePageListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 120px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					flex-direction: column;

					.leftSec {
						align-self: flex-start;
						.blackdarkTxt {
							padding-right: 14px;
							font-size: $fontSize16;
						}
						.divider {
							margin-right: 15px;
						}
						.greyTxt {
							padding-right: 20px;
						}
						.searchMain {
							width: 240px;
						}
					}
					.rightBtn {
						align-self: flex-end;
						margin-top: 10px;
						.cyanBtn {
							width: 150px;
							padding: 10px 8px;
						}
					}
				}
				.homepagelisting {
					margin: 30px 20px 15px 14px;
					padding: 16px 12px;
					.homepageHeader {
						display: none;
					}
					.sortDiv {
						display: flex;
						flex-direction: row;
						margin: 10px 0 0 0;
						align-items: center;
						font-size: $fontSize14;
						.linkSmall {
							margin-left: 8px;
							font-size: $fontSize16;
							color: $colorYellow;
							font-weight: 600;
							.greySmall {
								margin-left: 4px;
								color: $colorGrey;
								font-size: $fontSize14;
							}
							i {
								color: $colorYellow;
								font-size: $fontSize16;
								font-weight: 600;
								transform: rotate(90deg);
								margin-left: 4px;
								&.upArrow {
									transform: rotate(270deg);
								}
							}
						}
					}
					.homepageScroll {
						height: calc(100vh - 480px) !important;
						ul {
							.homepageRow {
								.nameImgRow {
									width: 50%;
									padding-top: 40px;
									&::before {
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
										content: "NAME";
										position: absolute;
										top: 0px;
									}
								}
								// .buttonRow {
								// 	width: 26%;
								// 	padding-top: 40px;
								// 	padding-left: 8%;
								// 	&::before {
								// 		content: "BUTTON COLOR";
								// 		position: absolute;
								// 		top: -13px;
								// 		left: 0;
								// 		color: $colorGrey;
								// 		font-size: $fontSize16;
								// 		font-weight: 600;
								// 		margin-bottom: 15px;
								// 	}
								// }
								.urlRow {
									width: 50%;
									position: relative;
									padding-top: 40px;
									&::before {
										content: "URL";
										position: absolute;
										top: -13px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}

								.clubRow {
									width: 33%;
									position: relative;
									padding-top: 60px;
									display: flex;
									// justify-content: center;
									.tooltipBig {
										margin-left: 20%;
									}
									&::before {
										content: "CLUB NAME";
										position: absolute;
										top: 18px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
										width: 100%;
										text-align: left;
									}
								}
								.actionsRow {
									width: 26%;
									position: relative;
									padding-top: 50px;
									.clickBtn {
										height: 40px;
										width: 40px;
										.round {
											padding: 3px 3px;
										}
									}
									&::before {
										content: "ACTIONS";
										position: absolute;
										top: 18px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.toggleRow {
									width: 40%;
									position: relative;
									padding-top: 60px;
									display: flex;
									// justify-content: center;
									.tooltipBig {
										margin-left: 20%;
									}
									&::before {
										content: "SHOW/HIDE ON MOBILE";
										position: absolute;
										top: 18px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
										width: 100%;
										text-align: left;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.HomePageListingPage {
		height: 100vh;
		.centerSec {
			.rightMain {
				height: calc(100vh - 140px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					flex-direction: column;

					.leftSec {
						align-self: flex-start;
						.blackdarkTxt {
							padding-right: 14px;
							font-size: $fontSize16;
						}
						.divider {
							margin-right: 15px;
						}
						.greyTxt {
							padding-right: 20px;
						}
						.searchMain {
							width: 240px;
						}
					}
					.rightBtn {
						align-self: flex-end;
						margin-top: 10px;
						.cyanBtn {
							width: 150px;
							padding: 10px 8px;
						}
					}
				}
				.homepagelisting {
					margin: 30px 20px 15px 14px;
					padding: 16px 12px;
					.homepageHeader {
						display: none;
					}
					.sortDiv {
						display: flex;
						flex-direction: row;
						margin: 10px 0 0 0;
						align-items: center;
						font-size: $fontSize14;
						.linkSmall {
							margin-left: 8px;
							font-size: $fontSize16;
							color: $colorYellow;
							font-weight: 600;
							.greySmall {
								margin-left: 4px;
								color: $colorGrey;
								font-size: $fontSize14;
							}
							i {
								color: $colorYellow;
								font-size: $fontSize16;
								font-weight: 600;
								transform: rotate(90deg);
								margin-left: 4px;
								&.upArrow {
									transform: rotate(270deg);
								}
							}
						}
					}
					.homepageScroll {
						height: calc(100vh - 450px) !important;
						ul {
							.homepageRow {
								.nameImgRow {
									width: 50%;
									padding-top: 40px;
									&::before {
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
										content: "NAME";
										position: absolute;
										top: 0px;
									}
								}
								// .buttonRow {
								// 	width: 26%;
								// 	padding-top: 40px;
								// 	padding-left: 8%;
								// 	&::before {
								// 		content: "BUTTON COLOR";
								// 		position: absolute;
								// 		top: -13px;
								// 		left: 0;
								// 		color: $colorGrey;
								// 		font-size: $fontSize16;
								// 		font-weight: 600;
								// 		margin-bottom: 15px;
								// 	}
								// }
								.urlRow {
									width: 50%;
									position: relative;
									padding-top: 40px;
									&::before {
										content: "URL";
										position: absolute;
										top: -13px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.clubRow {
									width: 33%;
									position: relative;
									padding-top: 60px;
									display: flex;
									// justify-content: center;
									.tooltipBig {
										margin-left: 20%;
									}
									&::before {
										content: "CLUB NAME";
										position: absolute;
										top: 18px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
										width: 100%;
										text-align: left;
									}
								}
								.actionsRow {
									width: 26%;
									position: relative;
									padding-top: 50px;
									.clickBtn {
										height: 40px;
										width: 40px;
										.round {
											padding: 3px 3px;
										}
									}
									&::before {
										content: "ACTIONS";
										position: absolute;
										top: 18px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.toggleRow {
									width: 40%;
									position: relative;
									padding-top: 60px;
									display: flex;
									// justify-content: center;
									.tooltipBig {
										margin-left: 20%;
									}
									&::before {
										content: "SHOW/HIDE ON MOBILE";
										position: absolute;
										top: 18px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
										width: 100%;
										text-align: left;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
