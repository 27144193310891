@import "../../assets/scss/variables.scss";

.textField {
	padding: 0;
	width: 100%;
	display: block;
	position: relative;
	margin-bottom: 30px;
	text-align: left;
	.labelTxt {
		font-size: $fontSize13;
		color: $colorGrey;

		display: inline-block;
	}
	.redStar {
		color: $colorLightRed;
		padding-left: 2px;
	}

	.inputBox {
		position: relative;
		.countryPhoneGroup {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.react-tel-input {
				width: 27%;
				.form-control {
					padding: 0 20px 0 17px;
					text-align: center;
					// position: absolute;
					// width: 70px;
					// top: 24px;
					// height: 40px;
					// border: none;
					// border-bottom: 1px solid $colorBorderBlue;
					// border-radius: 0;
					// padding: 0 18px 0 0px;
					// font-size: 18px;
					// font-weight: 400;
					// font-family: $primaryFont;

					&:focus {
						box-shadow: none;
					}

					&.form-control:disabled,
					.form-control[readonly] {
						background-color: $colorGreyBlack;
					}
				}

				.flag-dropdown {
					// top: 45px;
					// z-index: 999;
					// border: none;
					top: 5px;
					z-index: 999;
					border: none;
					background: transparent;

					.selected-flag {
						width: 72px;
						top: 0px;
						height: 40px;
						background: transparent;
						position: relative;
						&:before {
							content: "+";
							position: absolute;
							top: 10px;
							left: 6px;
						}

						.flag {
							background: transparent;

							.arrow {
								left: 56px;
								margin-top: 0px;
								// opacity: 0;
							}
						}
					}

					&:hover {
						.selected-flag {
							background: transparent;
						}
					}

					&:focus {
						.selected-flag {
							background: transparent;
						}
					}
				}

				.country-list {
					top: 45px;
					max-height: 150px;

					.country {
						&:focus {
							outline: none;
						}
					}
					.flag {
						display: none;
					}
				}
			}
			input {
				width: 80%;
			}
		}

		.icon-css {
			color: $colorYellow;
			position: absolute;
			top: 20px;
			right: 18px;
			font-size: $fontSize18;
			cursor: pointer;
		}
		.txtfieldImg {
			position: absolute;
			top: 12px;
			left: 18px;
		}
		.errorMsg {
			display: block;
			font-size: $fontSize12;
			text-align: left;
			padding: 0;
			margin: 0;
			color: $colorWhite;
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.5s ease-out;
		}
		.errorMsg.show {
			color: $colorRed;
			padding: 3px 18px;
			max-height: 50px;
			transition: max-height 0.5s ease-in;
		}
		.fieldError {
			transition: max-height 0.5s ease-in;
			.green {
				.errorMsg.show {
					color: $colorGreendark;
					padding: 3px 18px;
					max-height: 50px;
					transition: max-height 0.5s ease-in;
				}
			}
			.red {
				.errorMsg.show {
					color: $colorRed;
					padding: 3px 18px;
					max-height: 50px;
					transition: max-height 0.5s ease-in;
				}
			}
		}
		input {
			background: $bgLightGrey;
			color: $colorGreyBlack;
			font-size: $fontSize16;
			font-weight: 400;
			padding: 7px 38px 8px 16px;
			text-overflow: ellipsis;
			height: 54px;
			outline: 0px;
			width: 100%;
			border-radius: 20px;
			border: none;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;

			&::-webkit-input-placeholder {
				color: $colorGrey;
			}

			&::-moz-placeholder {
				color: $colorGrey;
			}

			&:-ms-input-placeholder {
				color: $colorGrey;
			}

			&:-moz-placeholder {
				color: $colorGrey;
			}

			&:focus {
				background: $bgWhite;
				border: 1px solid $bgYellow;
				outline: none;
				box-shadow: none;
			}
		}
		input::-webkit-credentials-auto-fill-button {
			visibility: hidden;
			display: none !important;
		}

		input[type="number"]::-webkit-inner-spin-button,
		input[type="number"]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			margin: 0;
		}

		input[type="number"] {
			-moz-appearance: textfield;
		}

		input[type="number"]::-webkit-inner-spin-button,
		input[type="number"]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		input::-ms-clear,
		input::-ms-reveal {
			display: none;
		}

		.dropDown__control {
			background: $bgLightGrey;
			border-radius: 20px;
			border: none;
			box-shadow: none;
			display: flex;
			align-items: center;
			cursor: pointer;
			.dropDown__value-container {
				display: flex;
				align-items: center;
				padding: 8px 0px 8px 16px;
				.dropDown__placeholder {
					color: $colorGrey;
				}
				div {
					&:last-child {
						padding: 0;
						margin: 0;
					}
				}
				input {
					height: 28px;
				}
			}
			.dropDown__indicators {
				.dropDown__indicator-separator {
					display: none;
				}
				.dropDown__indicator {
					padding: 16px 8px;
				}
			}
			&:focus {
				background: $bgWhite;
				box-shadow: 1px solid $bgYellow;
			}
		}
		.dropDown__menu {
			left: 4%;
			width: 92%;
			.dropDown__menu-list {
				max-height: 210px;
				.dropDown__option {
					cursor: pointer;
				}
				.dropDown__option--is-focused {
					// background-color: rgba($color: $bgYellow, $alpha: 0.4);
					background-color: rgb(241, 241, 241);
				}
			}
		}
	}

	.form-control {
		background: $bgLightGrey;
		color: $colorGreyBlack;
		font-size: $fontSize16;
		font-weight: 400;
		padding: 7px 16px 8px 16px;
		text-overflow: ellipsis;
		height: 54px;
		outline: 0px;
		width: 100%;
		border-radius: 20px;
		border: none;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;

		&::-webkit-input-placeholder {
			color: $colorGrey;
		}

		&::-moz-placeholder {
			color: $colorGrey;
		}

		&:-ms-input-placeholder {
			color: $colorGrey;
		}

		&:-moz-placeholder {
			color: $colorGrey;
		}

		&:focus {
			background: $bgWhite;
			border: 1px solid $bgYellow;
			outline: none;
			box-shadow: none;
		}

		&.error {
			border-color: $colorRed;
		}
	}

	.maiwai-search {
		position: absolute;
		left: 30px;
		font-size: $fontSize16;
		color: $bgGrey;
		top: 27px;
	}

	.form-group {
		margin-bottom: 0px;
	}
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.textField {
		.inputBox {
			.countryPhoneGroup {
				.react-tel-input {
					.flag-dropdown {
						.selected-flag {
							.flag {
								.arrow {
									left: 78px;
								}
							}
						}
					}
				}
			}
		}
	}
}
