@import "../../assets/scss/variables.scss";
.FixturesTablesListingPage {
	width: 100%;
	position: relative;
	float: left;
	overflow: hidden;
	height: 100%;
	.centerSec {
		width: 100%;
		float: left;
		padding-top: 60px;
		background: $bglightBlue;
		height: 100%;
		.rightMain {
			float: left;
			height: calc(100vh - 140px) !important;
			width: 100%;
			.searchSec {
				background: $bgWhite;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 12px 40px 12px 32px;
				.backICON {
					color: $colorDarkBlue;
					font-size: $fontSize20;
					padding-right: 10px;
				}
				.blackdarkTxt {
					color: $colorDarkBlue;
					font-size: $fontSize18;
					padding-right: 25px;
					font-weight: 500;
					padding-left: 10px;
				}
				.divider {
					height: 30px;
					width: 2px;
					display: block;
					background: $bgLightGrey;
					margin-right: 25px;
				}
				.greyTxt {
					color: $colorGrey;
					padding-right: 40px;
					font-size: $fontSize16;
				}
				.searchMain {
					width: 260px;
				}
			}
			.fixturestableslisting {
				height: calc(100vh - 200px) !important;

				.fixturesScroll {
					padding: 40px 60px 40px 60px;
					display: block;
					height: 100%;
					.listingTopImgSec {
						display: flex;
						justify-content: space-between;
						align-items: center;
						background: $bgWhite;
						border-radius: 20px;
						height: 95px;
						margin-bottom: 80px;

						.backImgSec {
							background: url("../../assets/images/dottedBg.png");
							height: 100%;
							width: 30%;
							background-size: cover;
							border-radius: 20px 0 20px 20px;
							position: relative;
							background-position: center center;
							.imgCircle {
								background: $bgWhite;
								width: 105px;
								height: 105px;
								border-radius: 50%;
								display: flex;
								align-items: center;
								justify-content: center;
								position: absolute;
								position: absolute;
								bottom: -50%;
								left: 50%;
								-webkit-transform: translateX(-50%);
								transform: translateX(-50%);
								border: 1px solid $imgroundBorder;
								.imgDiv {
									height: 65px;
									width: 65px;

									.clublogoMain {
										height: 100%;
										width: 100%;
										object-fit: contain;
									}
								}
							}
						}
						.blacTxtSec {
							width: 30%;
							text-align: left;
							padding-left: 40px;
							.blacTxt {
								color: $colorDarkBlue;
								font-size: $fontSize18;
								font-weight: 500;
							}
						}
						.greyTxtSec {
							width: 20%;
							.greyTxt {
								color: $colorGrey;
								font-size: $fontSize16;
								text-align: left;
							}
						}
					}
					.txtBtnSec {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						margin-bottom: 30px;
						.greyBtnSecTxt {
							color: $colorGrey;
							font-size: $fontSize16;
							width: 70%;
							text-align: left;
						}
						.submitBtn {
							height: 40px;
							font-size: $fontSize12;
							position: relative;
							border-radius: 15px;
							width: auto;
							padding: 12px 22px;
							.clubIcon {
								margin-right: 10px;
								font-size: $fontSize20;
							}
						}
					}

					.fixtureCardSec {
						display: block;
						ul {
							display: flex;
							flex-wrap: wrap;
							list-style: none;
							flex-direction: row;
							width: 100%;
							margin: 0;
							padding: 0;
							li {
								display: flex;
								flex-wrap: wrap;
								flex-direction: row;
								.fixtureCard {
									background-color: $bgWhite;
									border-radius: 20px;
									display: flex;
									flex-direction: column;
									align-items: center;
									// justify-content: center;
									padding: 22px 32px;
									width: 255px;
									margin: 0 35px 40px 0;
									// &:nth-child(6n) {
									// 	margin: 0 0 20px 0;
									// }
									&:hover {
										box-shadow: 0px 2px 20px 0px $imgroundBorder;
									}
									.fixturesImg {
										height: 140px;
										display: block;
										img {
											height: 100%;
											width: 100%;
											object-fit: contain;
										}
									}

									.bottomSec {
										margin-top: 20px;
										display: flex;
										flex-direction: column;
										height: 100%;
										justify-content: space-between;
										align-items: center;

										.blueBigTxt {
											display: block;
											text-align: center;
											color: $colorDarkBlue;
											font-size: $fontSize24;
											font-weight: 500;
											word-break: break-word;
										}
										.greycardSubTxt {
											display: block;
											text-align: center;
											color: $colorGrey;
											font-size: $fontSize14;
											word-break: break-word;
										}
										.manageSec {
											display: flex;
											flex-direction: row;
											align-items: center;
											margin-top: 20px;
											.tooltipSmall {
												padding: 10px 14px;
											}
											a {
												padding: 10px 14px;
												background: $bgLightGrey;
												border-radius: 12px;
												margin-right: 15px;
												height: 46px;
												width: 46px;
												i {
													color: $colorGrey;
												}
											}
										}
										.toggle {
											padding-top: 20px;

											.switch {
												position: relative;
												display: inline-block;
												width: 45px;
												height: 20px;
												background-color: #99adc4;
												border-radius: 20px;
												transition: all 0.3s;
												cursor: pointer;
											}
											.switch::after {
												content: "";
												position: absolute;
												width: 32px;
												height: 32px;
												border-radius: 50%;
												background-color: $lightToggle;
												top: -6px;
												left: 0px;
												transition: all 0.3s;
											}

											.checkbox:checked + .switch::after {
												background-color: $bgYellow;
												left: 16px;
											}
											.checkbox:checked + .switch {
												background-color: $colorLightYellow;
											}
											.checkbox {
												display: none;
											}
										}
									}
								}
							}
						}
					}
					.emptyData {
						height: 50%;
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
	.FixturesTablesListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 150px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					.backICON {
						.blackdarkTxt {
							padding-right: 10px;
							text-align: left;
							font-size: $fontSize18;
						}
					}

					.divider {
						margin-right: 20px;
					}
					.searchMain {
						width: 260px;
					}
				}
				.fixturestableslisting {
					height: calc(100vh - 190px) !important;
					.fixturesScroll {
						padding: 40px 20px 40px 20px;
						.fixtureCardSec {
							ul {
								li {
									.fixtureCard {
										width: 210px;
										padding: 22px 12px;
										margin: 0 30px 20px 0;
										.fixturesImg {
											height: 130px;
										}
										.bottomSec {
											.blueBigTxt {
												font-size: $fontSize22;
											}
										}
										&:nth-child(4n) {
											margin: 0 0 20px 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.FixturesTablesListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 200px) !important;
				.fixturestableslisting {
					height: calc(100vh - 200px) !important;
					.fixturesScroll {
						padding: 40px 30px 40px 30px;
						.fixtureCardSec {
							ul {
								li {
									.fixtureCard {
										width: 220px;
										padding: 22px 22px;
										margin: 0 25px 20px 0;
										.fixturesImg {
											height: 110px;
										}
										.bottomSec {
											.blueBigTxt {
												font-size: $fontSize22;
											}
										}
										&:nth-child(4n) {
											margin: 0 0 20px 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
	.FixturesTablesListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 150px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					.backICON {
						.blackdarkTxt {
							padding-right: 10px;
							text-align: left;
							font-size: $fontSize18;
						}
					}

					.divider {
						margin-right: 20px;
					}
					.searchMain {
						width: 260px;
					}
				}
				.fixturestableslisting {
					height: calc(100vh - 190px) !important;
					.fixturesScroll {
						padding: 40px 20px 40px 20px;
						.fixtureCardSec {
							ul {
								li {
									.fixtureCard {
										width: 220px;
										padding: 22px 12px;
										margin: 0 30px 20px 0;
										.fixturesImg {
											height: 130px;
										}
										.bottomSec {
											.blueBigTxt {
												font-size: $fontSize22;
											}
										}
										&:nth-child(3n) {
											margin: 0 0 20px 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 767px) {
	.FixturesTablesListingPage {
		height: 100vh;
		.centerSec {
			.rightMain {
				height: calc(100vh - 215px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					justify-content: space-between;
					.backICON {
						.blackdarkTxt {
							padding-right: 0px;
							text-align: left;
							font-size: $fontSize18;
						}
					}

					.divider {
						margin-right: 0px;
					}
					.searchMain {
						width: 210px;
					}
				}
				.fixturestableslisting {
					height: calc(100vh - 160px) !important;
					.fixturesScroll {
						padding: 40px 30px 40px 30px;
						.fixtureCardSec {
							ul {
								li {
									.fixtureCard {
										width: 182px;
										padding: 18px 12px;
										margin: 0 16px 20px 0;
										.fixturesImg {
											height: 90px;
										}
										.bottomSec {
											.blueBigTxt {
												font-size: $fontSize20;
											}
										}
										&:nth-child(3n) {
											margin: 0 0 20px 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
