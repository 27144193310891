@import "../../assets/scss/variables.scss";
.componentPagination {
  position: relative;
  display: flex;
  justify-content: space-between;
  .pagination {
    // border: 1px solid $colorBorderLight;
    padding: 5px 4px;
    display: flex;
    align-items: center;
    width: 400px;
    position: relative;
    margin: 0;
    li {
      padding: 2px 8px;
      a {
        color: $noPagination;
        .iconArrow {
        }
        text-decoration: none !important;
      }
      &.active {
        padding: 2px 8px;
        border-radius: 4px;
        background: $bgLightDarkYellow;
        a {
          color: $noPagination;
        }
      }
      .leftDiv {
        font-size: $fontSize12;
        background: $bglightBlue;
        padding: 5px 4px;
        border-radius: 8px;
        color: $colorGrey;
        i {
          font-size: $fontSize12;
        }
      }
      .iconArrow {
        color: $colorGrey;
        font-size: $fontSize12;
        background: $bgPagination;
        padding: 8px 8px;
        border-radius: 8px;
        &.arrow {
        }
      }
      .icon-right-arrow {
        &.rightArrow {
        }
      }

      // &:last-child {
      //   transform: rotate(180deg);
      // }
    }
  }
  .goto {
    position: absolute;
    top: 5px;
    // right: 30%;
    right: 0;
    font-size: $fontSize16;
    color: $colorGrey;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
