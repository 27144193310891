@import "../../assets/scss/variables.scss";

.SearchBox {
  padding: 0;
  width: 100%;
  display: inline-block;
  position: relative;
  .inputBox {
    position: relative;
  }
  .form-group {
    margin: 0;
  }
  .form-control {
    background: $bgLightGrey;
    color: $colorGreyBlack;
    font-size: $fontSize16;
    font-weight: 400;
    padding: 7px 40px 8px 16px;
    text-overflow: ellipsis;
    height: 40px;
    outline: 0px;
    width: 100%;
    border-radius: 20px;
    position: relative;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-webkit-input-placeholder {
      color: $colorGrey;
    }
    &::-moz-placeholder {
      color: $colorGrey;
    }
    &:-ms-input-placeholder {
      color: $colorGrey;
    }
    &:-moz-placeholder {
      color: $colorGrey;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  i {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: $fontSize16;
    color: $colorGrey;
    font-weight: 600;
    cursor: pointer;
  }
}
