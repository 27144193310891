@import "../../assets/scss/variables.scss";
.UsermodalMain {
  position: relative;
  display: flex;
  flex-direction: row;
  form {
    width: 100%;
    display: flex;
    .leftSec {
      width: 50%;
      margin: 15px 0px;
      padding-left: 25px;

      .dropDownSec {
        width: 90%;
        margin-top: 30px;
      }
    }

    .rightSec {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid $bgLightGrey;

      .formSec {
        margin: 30px 0;
        display: flex;
        flex-direction: column;
        width: 85%;
        .textField {
          margin-bottom: 40px;
          .form-group {
            .errorMsg {
              position: absolute;
            }
          }
          .countryPhoneGroup ~ .errorMsg {
            left: 25%;
          }
        }
        .btnGroup {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          .submitBtn {
            width: 35%;
            height: 40px;
            // padding: 8px 42px;
          }
          .cancelBtn {
            width: 35%;
            height: 40px;
            // padding: 8px 28px;
            margin-left: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .UsermodalMain {
    form {
      .leftSec {
        padding-left: 15px;
        .uploadArea {
          .txtDropzone {
            .txtSec {
              width: 70%;
            }
          }
        }
      }
      .rightSec {
        .formSec {
          margin: 15px 0;
          display: flex;
          flex-direction: column;
          .textField {
            margin-bottom: 30px;
          }
          .btnGroup {
            .submitBtn {
              width: 45%;
              height: 40px;
            }
            .cancelBtn {
              width: 45%;
              height: 40px;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .UsermodalMain {
    form {
      .leftSec {
        padding-left: 0px;
        .uploadArea {
          .txtDropzone {
            .txtSec {
              width: 70%;
            }
          }
        }
      }
      .rightSec {
        .formSec {
          margin: 15px 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          padding-left: 20px;
          .textField {
            margin-bottom: 30px;
            .countryPhoneGroup {
              .react-tel-input {
                width: 38%;
                .flag-dropdown {
                  .selected-flag {
                    .flag {
                      .arrow {
                        left: 55px;
                      }
                    }
                  }
                }
              }
              input {
                width: 82%;
              }
            }
            .countryPhoneGroup ~ .errorMsg {
              left: 30%;
            }
          }
          .btnGroup {
            .submitBtn {
              width: 45%;
              height: 40px;
            }
            .cancelBtn {
              width: 45%;
              height: 40px;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .UsermodalMain {
    form {
      flex-direction: column;
      .leftSec {
        width: 100%;
        margin: 0px 0px;
        padding-left: 0px;
        .uploadArea {
          .txtDropzone {
            margin-bottom: 20px;
            .txtSec {
              width: 60%;
            }
          }
        }
        .dropDownSec {
          width: 100%;
        }
      }
      .divider {
        display: none;
      }
      .rightSec {
        width: 100%;
        border: none;
        .formSec {
          margin: 10px 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          .textField {
            margin-bottom: 30px;
          }
          .btnGroup {
            .submitBtn {
              width: 30%;
              height: 40px;
            }
            .cancelBtn {
              width: 30%;
              height: 40px;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}
