@import "../../assets/scss/variables.scss";
.customDropdown {
    margin: 0 !important;
    border-radius: 45px;
    .labelTxt {
        font-size: $fontSize13;
        color: $colorGrey;
        display: inline-block;
    }
    .redStar {
        color: $colorLightRed;
        padding-left: 2px;
    }

    .selected {
        .btn-primary {
            color: $colorBlack !important;
            font-weight: 500;
        }
    }
    .dropdown {
        .btn-primary {
            color: $colorGrey;
            position: relative;
            background-color: $bgLightGrey !important;
            border: 1px solid $colorBorderBlue;
            width: 100%;
            font-size: $fontSize14;
            text-align: left;
            padding: 10px 15px;
            border-radius: 45px;

            &:focus {
                box-shadow: none !important;
                border: 1px solid $colorYellow;
                background-color: $bgWhite !important;
            }
            &:active {
                color: $colorGrey;
                border: 1px solid $colorYellow;
                background-color: $bgWhite !important;
            }

            &::after {
                content: "\e912";
                font-family: "greystone" !important;
                border: none;
                color: $colorGrey;
                font-size: $fontSize10;
                position: absolute;
                right: 15px;
                top: 15px;
                transform: rotate(90deg);
            }
        }

        color: $colorGrey;
        text-align: left;
        .dropdown-menu {
            // width: 100%;
            width: 92%;
            left: 4% !important;
            padding: 0;
            .dropdownData {
                display: block;
                width: 100%;
                max-height: 150px;
                overflow-y: scroll;
                padding: 0;
                scrollbar-color: #d2d2d2 $bgWhite;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                .dropdown-item {
                    font-size: $fontSize14;
                    font-weight: 600;
                    padding: 0;
                    color: #000;
                    // padding-bottom: 8px;
                    // padding-top: 8px;
                    padding: 8px 15px !important;
                    &:hover {
                        // background: none;
                    }
                    &:active {
                        background: none;
                    }
                }
            }
        }
    }
}
