@import "../../assets/scss/variables.scss";
.checkboxMain {
	// Checkbox Css
	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked {
		position: absolute;
		left: -9999px;
	}
	[type="checkbox"]:not(:checked) + label,
	[type="checkbox"]:checked + label {
		position: relative;
		padding-left: 30px;
		cursor: pointer;
		margin-bottom: 0px;
		display: flex;
		align-items: center;
	}
	/* checkbox aspect */
	[type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:before {
		position: absolute;
		left: 0;
		top: 1px;
		content: "\e91b";
		font-size: 20px;
		color: #2d2f32;
		font-family: "greystone" !important;
		line-height: normal;
	}
	/* checked mark aspect */
	[type="checkbox"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:after {
		content: "\e91a";
		position: absolute;
		top: 1px;
		left: 0;
		font-size: 20px;
		color: #2d2f32;
		font-family: "greystone" !important;
		line-height: normal;
	}
	/* checked mark aspect changes */
	[type="checkbox"]:not(:checked) + label:after {
		opacity: 0;
		// transform: scale(0);
	}
	[type="checkbox"]:checked + label:after {
		opacity: 1;
		// transform: scale(1);
	}
	// Checkbox Css
	label {
		font-size: $fontSize16;
		font-weight: 500;
		color: $colorBlack;
	}
}
