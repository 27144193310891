@import "../../assets/scss/variables.scss";
.HomepagemodalMain {
  position: relative;
  display: flex;
  flex-direction: column;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .uploadSec {
    }

    .formSec {
      margin: 20px 0 10px 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      .textField {
        margin-bottom: 40px;
        .form-group {
          .errorMsg {
            position: absolute;
          }
        }
      }
      .colorSec {
        display: flex;
        align-items: center;
        position: relative;
        label {
          font-size: 13px;
          color: $colorGrey;
          display: inline-block;
          margin: 0;
          i {
            color: $colorLightRed;
            padding-left: 2px;
          }
        }
        .swatch {
          height: 30px;
          width: 30px;
          border-radius: 8px;
          display: inline-block;
          margin-left: 20px;
          position: relative;
          cursor: pointer;
          .color {
            border-radius: 8px;
            height: 30px;
            width: 30px;
            border: 0.1px solid $colorGrey;
          }
        }
        .popover {
          position: absolute;
          z-index: 2;
          position: absolute;
          left: 175px;
          bottom: -18px;
          border: none;
          .cover {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
          }
          .sketch-picker {
            position: absolute;
            bottom: 0;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px !important;
            border-radius: 14px !important;
            cursor: pointer;
            &.visible{
              display: block;
            }
            &.notVisible{
              display: none;
            }
            &::before {
              content: "";
              height: 31px;
              width: 30px;
              position: absolute;
              bottom: 22px;
              left: -12px;
              display: inline-block;
              vertical-align: middle;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 10px solid #e8e8e8;
            }
            .flexbox-fix {
              // background: red;
              &:nth-child(1) {
                background: red;
              }
            }

            div {
              &:nth-child(1) {
              }
              &:nth-child(2) {
                div {
                  &:nth-child(1) {
                    div {
                      &:nth-child(2) {
                      }
                    }
                  }
                  &:nth-child(2) {
                  }
                }
              }
              &:nth-child(3) {
                div {
                  &:nth-child(5) {
                    display: none;
                  }
                }
              }
              &:nth-child(4) {
              }
            }
          }
        }
      }
      .btnGroup {
        display: flex;
        justify-content: space-between;
        margin-top: 45px;
        .submitBtn {
          width: 35%;
          height: 40px;
          // padding: 8px 42px;
        }
        .cancelBtn {
          width: 35%;
          height: 40px;
          // padding: 8px 28px;
          margin-left: 30px;
        }
      }
    }
  }
}

// @media only screen and (min-width: 993px) and (max-width: 1024px) {
//   .HomepagemodalMain {
//     .leftSec {
//       .uploadArea {
//         .txtDropzone {
//           .txtSec {
//             width: 70%;
//           }
//         }
//       }
//     }
//     .rightSec {
//       form {
//         width: 82%;
//       }
//       .formSec {
//         margin: 30px 0;
//         display: flex;
//         flex-direction: column;
//         .textField {
//           margin-bottom: 30px;
//         }
//         .btnGroup {
//           .submitBtn {
//             width: 45%;
//             height: 40px;
//           }
//           .cancelBtn {
//             width: 45%;
//             height: 40px;
//             margin-left: 30px;
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 768px) and (max-width: 992px) {
//   .HomepagemodalMain {
//     .leftSec {
//       .uploadArea {
//         .txtDropzone {
//           .txtSec {
//             width: 70%;
//           }
//         }
//       }
//     }
//     .rightSec {
//       form {
//         width: 82%;
//       }
//       .formSec {
//         margin: 30px 0;
//         display: flex;
//         flex-direction: column;
//         .textField {
//           margin-bottom: 30px;
//         }
//         .btnGroup {
//           .submitBtn {
//             width: 45%;
//             height: 40px;
//           }
//           .cancelBtn {
//             width: 45%;
//             height: 40px;
//             margin-left: 30px;
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 480px) and (max-width: 767px) {
//   .HomepagemodalMain {
//     flex-direction: column;
//     .leftSec {
//       width: 100%;
//       margin: 0px 0px;

//       .uploadArea {
//         .txtDropzone {
//           margin-bottom: 20px;
//           .txtSec {
//             width: 60%;
//           }
//         }
//       }
//       .dropDownSec {
//         width: 100%;
//       }
//     }
//     .divider {
//       display: none;
//     }
//     .rightSec {
//       width: 100%;
//       border: none;
//       form {
//         width: 100%;
//       }
//       .formSec {
//         margin: 10px 0;
//         display: flex;
//         flex-direction: column;
//         .textField {
//           margin-bottom: 30px;
//         }
//         .btnGroup {
//           .submitBtn {
//             width: 30%;
//             height: 40px;
//           }
//           .cancelBtn {
//             width: 30%;
//             height: 40px;
//             margin-left: 30px;
//           }
//         }
//       }
//     }
//   }
// }

.sketch-picker > div:nth-child(1) {
  border: 1px solid #dedede;
  border-radius: 8px;
}
.sketch-picker > div:nth-child(2) {
  align-items: center;
}
.sketch-picker > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) {
  display: none;
}
