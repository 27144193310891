@import "../../assets/scss/variables.scss";
.errorMsgupload {
  display: block;
  font-size: $fontSize12;
  text-align: left;
  padding: 0;
  margin: 0;
  color: $colorWhite;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  &.show {
    color: $colorRed;
    padding: 5px 0px;
    max-height: 50px;
    transition: max-height 0.5s ease-in;
    position: absolute;
  }
}
.dropzone-wrapper {
  margin-bottom: 10px;
  .uploadArea {
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 120px;
    .uploadview {
      .containerDropzone {
        width: 100%;
        display: flex;
        .allBoxView {
          height: 100%;
          width: 100%;
        }
        // .allBoxView {
        //   display: flex;
        //   flex-direction: row;
        //   height: 100%;
        //   width: 100%;
        //   .imgViewBox {
        //     height: 90px;
        //     width: 90px;
        //     margin-right: 10px;
        //     margin-bottom: 30px;
        //     position: relative;
        //     img {
        //       object-fit: contain;
        //       width: 100%;
        //       height: 100%;
        //       border-radius: 12px;
        //     }
        //     .bottomMobileIcon {
        //       i {
        //         padding-top: 5px;
        //         color: $colorGrey;
        //         font-size: $fontSize16;
        //       }
        //     }
        //     .numberSize {
        //       // width: 32px;
        //       height: 14px;
        //       border-radius: 6px;
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;
        //       position: absolute;
        //       right: 5px;
        //       top: 5px;
        //       background: $bglightred;
        //       color: $colorWhite;
        //       font-size: $fontSize10;
        //       padding: 4px;
        //       z-index: 99;
        //     }
        //     .overlay {
        //       position: absolute;
        //       height: 100%;
        //       width: 100%;
        //       background: $bgBlack;
        //       opacity: 0.1;
        //       top: 0;
        //       border-radius: 12px;
        //       z-index: 9;
        //     }
        //   }
        //   .touchBox {
        //     height: 100%;
        //     width: 100%;
        //     .uploadBox {
        //       height: 100%;
        //       width: 95%;
        //       border: 1px dashed $colorBorderBlue;
        //       border-radius: 12px;
        //       display: flex;
        //       justify-content: center;
        //       align-items: center;
        //       flex-direction: column;
        //       margin-right: 5px;
        //       i {
        //         color: $colorGrey;
        //         font-size: $fontSize20;
        //       }
        //       a {
        //         color: $colorGrey;
        //         font-size: $fontSize16;
        //         padding-top: 15px;
        //       }
        //     }
        //   }
        // }

        .txtDropzone {
          display: flex;
          z-index: 999;
          // padding: 34px 60px;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          width: 100%;
          .uploadZone {
            display: flex;
            .iconSec {
              width: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              // background: red
              .icon {
                display: flex;
                position: absolute;
                // color: grey;
                font-size: 30px;
                color: $colorDarkGrey;
              }
              .plus {
                position: absolute;
                color: $colorWhite;
                font-size: $fontSize18;
                z-index: 9;
              }
            }
            .divider {
              height: 48px;
              width: 2px;
              display: block;
              background: $bgLightGrey;
              margin: 0 15px;
            }
            .txtSec {
              display: flex;
              width: 150px;
            }
            .whiteTxt {
              color: $colorWhite;
              font-size: $fontSize14;
              margin: 0;
            }
            a {
              color: $colorBlue;
              font-size: $fontSize16;
              display: inline-block;
              outline: none;
            }
          }

          .imgBack {
            height: 110px;
            display: block;
            width: 100%;
            .editTXTView {
              color: $colorYellow;
              font-size: $fontSize14;
              font-weight: 700;
              text-align: center;
              margin-top: 5px;
              display: block;
              i {
                color: $colorGrey;
              }
            }
            .bgOverlay {
              background: $bgBlack;
              opacity: 0.2;
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              border-radius: 10px;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
        }
        .dottedOverlay {
          border: 3px dashed $colorWhite;
          height: 80%;
          width: 90%;
          position: absolute;
          top: 12px;
          left: 15px;
          z-index: 99;
          border-radius: 10px;
        }

        .bgOverlay {
          background: $bgBlack;
          opacity: 0.3;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          border-radius: 10px;
        }
      }
    }
  }
}
.partitionView {
  margin-bottom: 10px;
  .uploadArea {
    outline: none;
    cursor: pointer;
    .txtDropzone {
      display: flex;
      align-items: center;
      flex-direction: row;
      // justify-content: space-between;
      width: 100%;

      .mainImgSec {
        height: 120px;
        width: 120px;
        display: block;
        border-radius: 8px;
        position: relative;
        .mainImg {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 8px;
        }
      }
      .greyBack {
        border-radius: 8px;
        background: $bgLightGrey;
        height: 120px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          height: 100% !important;
          width: 100% !important;
          object-fit: contain;
        }
        .circlegrey {
          background: #cfd3d5;
          height: 68px;
          width: 68px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: $fontSize26;
            color: #a0a9b0;
          }
          .icongreyTxt {
            font-size: $fontSize16;
            font-weight: 700;
            color: #a7a7a7;
            margin-bottom: 0;
          }
        }
        .editView {
          position: absolute;
          bottom: -10px;
          right: -10px;
          padding: 6px 10px;
          border-radius: 50px;
          background: $bgWhite;
          z-index: 999;
          cursor: pointer;
          box-shadow: -1px -1px 6px 0px #d0d0d0;

          i {
            color: $colorGrey;
          }
        }
      }
      .txtSec {
        width: 50%;
        padding-left: 4%;
        .blueTxt {
          display: block;
          color: $colorDarkBlue;
          margin-bottom: 8px;
        }
        .whiteTxt {
          color: $colorGrey;
          display: block;
        }
        a {
          color: $colorBlue;
          display: inline-block;
        }
      }
    }
  }
}

.bannerLongView {
  margin-bottom: 20px;
  .fullWidthUploadArea {
    margin: 12px 0 28px 0;
    .containerDropzone {
      .bannerSecDropzone {
        display: flex;
        width: 100%;
        height: 100%;

        .bannerImgRow {
          background: $bgLightGrey;
          // padding: 16px 20px;
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: space-between;
          border-radius: 12px;
          align-items: center;
          cursor: pointer;

          position: relative;
          .iconRealImg {
            .bannerImg {
              border-radius: 8px;
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          .closeIcon {
            position: absolute;
            top: -10px;
            right: -6px;
            background: $bglightBlue;
            border-radius: 15px;
            height: 22px;
            width: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px !important;
            z-index: 999;
            i {
              color: $colorDarkBlue;
              font-size: $fontSize14;
            }
          }
        }

        .uploadSec {
          background: #e9edef;
          border-radius: 12px;
          display: flex;
          width: 100%;
          padding: 24px 20px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          i {
            color: $colorGrey;
            font-size: $fontSize20;
          }
          a {
            color: $colorGrey;
            font-size: $fontSize16;
            padding-left: 10px;
          }
          .blue {
            color: $colorBlue;
            padding-left: 8px;
          }
        }
        .imgViewSec {
          height: 60px;
          display: flex;
          justify-content: center;
          background: #e9edef;
          border-radius: 12px;
          cursor: pointer;
          img {
            object-fit: contain;
            height: 100%;
          }
        }
      }
    }
  }
  .tabletUploadArea {
    display: none;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .bannerLongView {
    // .fullWidthUploadArea {
    //   display: none;
    // }
    .tabletUploadArea {
      // margin: 20px 0;
      // padding-right: 0px;
      // display: flex;
      display: none;
      .allBoxView {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .imgViewBox {
          height: 90px;
          width: 90px;
          margin-right: 10px;
          margin-bottom: 30px;
          position: relative;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            border-radius: 12px;
          }
          .bottomMobileIcon {
            i {
              padding-top: 5px;
              color: $colorGrey;
              font-size: $fontSize16;
            }
          }
          .numberSize {
            // width: 32px;
            height: 14px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 5px;
            top: 5px;
            background: $bglightred;
            color: $colorWhite;
            font-size: $fontSize10;
            padding: 4px;
            z-index: 99;
          }
          .overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            background: $bgBlack;
            opacity: 0.1;
            top: 0;
            border-radius: 12px;
            z-index: 9;
          }
        }
      }
      .touchBox {
        height: 100%;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
        .uploadBox {
          height: 100%;
          width: 95%;
          border: 1px dashed $colorBorderBlue;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-right: 5px;
          i {
            color: $colorGrey;
            font-size: $fontSize20;
          }
          a {
            color: $colorGrey;
            font-size: $fontSize16;
            padding-top: 15px;
          }
        }
      }
    }
  }
}
