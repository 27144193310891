@import "../../assets/scss/variables.scss";

.CustomMenuBar {
	position: fixed;
	left: 0;
	z-index: 999;
	background: $bgWhite;
	height: 100%;
	border-radius: 0px 30px 20px 0px;
	transition: width 0.5s ease;
	-webkit-transition: width 0.5s ease;
	-moz-transition: width 0.5s ease;
	-ms-transition: width 0.5s ease;
	-o-transition: width 0.5s ease;
	width: 330px;
	overflow: hidden;
	&.barclose {
		width: 60px;
		transition: width 0.5s ease;
		-webkit-transition: width 0.5s ease;
		-moz-transition: width 0.5s ease;
		-ms-transition: width 0.5s ease;
		-o-transition: width 0.5s ease;
	}
	.sideBar {
		width: 330px;
		height: 100%;
		position: absolute;
		z-index: 9;
		left: 0;
		top: 0;
		.lineIcon {
			display: none;
		}
		.logoSec {
			padding: 8px 20px;
			display: flex;
			align-items: center;
			flex-direction: row;
			height: 62px;
			.logo {
				// height: 40px;
				// width: 50px;
				margin-left: -10px;
				// &.small {
				// 	height: 40px;
				// 	width: 50px;
				//
				// 	transition: margin-left 0.2s ease-out;
				// }
				.img {
					height: 100%;
					width: 100%;
				}
			}
			.headerTxt {
				color: $colorYellow;
				font-size: $fontSize12;
			}
			.closeIcon {
				display: none;
			}
		}
		.menuBar {
			padding: 0px 20px 35px 0;
			display: block;
			border-bottom: 2px solid $colorBorderBlue;
			.greyTxt {
				color: $colorGrey;
				font-size: $fontSize12;
				text-transform: uppercase;
				display: block;
				text-align: left;
				margin-bottom: 0px;
				padding: 24px 22px;
				height: 62px;
			}
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				.submenu {
					transition: max-height 0.6s ease-out;
					max-height: 0;
					overflow: hidden;
					margin: 0;
					padding: 0;
					.submenuLine {
						color: $colorYellow;
						font-size: $fontSize14;
						cursor: pointer;
						padding: 10px 0 10px 0px;

						.submenuTxt {
							margin-left: 70px;
							position: relative;
							padding-left: 20px;
							&::before {
								content: "";
								position: absolute;
								width: 6px;
								height: 6px;
								border-radius: 10px;
								background: $bgDarkBlue;
								top: 7px;
								left: 0;
							}
						}
					}
					&.active {
						max-height: 50px;
						opacity: 1;
						transition: max-height 0.6s ease-out;
					}
				}
				a {
					cursor: pointer;
					width: 100%;
				}
				.LinkSec {
					width: 100%;
					.simple {
						display: flex;
						align-items: center;
						padding: 14px 20px;
						width: 100%;
						justify-content: space-between;
						position: relative;

						.arrow {
							// background: red;
							width: 28px;
							height: 24px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.textLink {
							width: 70%;
							display: flex;
							align-items: center;
							justify-content: flex-start;
						}

						&.active {
							background: $bgLightGrey;
							border-radius: 0 50px 50px 0;

							span {
								color: $colorYellow;
								font-weight: 500;
							}
							&::after {
								content: "";
								position: absolute;
								border-left: 7px solid $colorYellow;
								display: block;
								background: $colorYellow;
								height: 100%;
								left: 0;
							}
						}
						.menuIcon {
							height: 24px;
							width: 24px;
							margin-right: 25px;
						}
						span {
							color: $colorDarkBlue;
							font-size: $fontSize16;
							font-weight: 600;
						}
						.right {
							color: $colorGrey;
							font-size: $fontSize14;
							transition: 0.4s;
							&.down {
								-o-transform: rotate(90deg);
								-ms-transform: rotate(90deg);
								-moz-transform: rotate(90deg);
								-webkit-transform: rotate(90deg);
								transform: rotate(90deg);
								transition: 0.4s;
							}
						}
					}
				}
			}
		}
		.clubListing {
			padding: 20px 0;
			.greyTxt {
				color: $colorGrey;
				font-size: $fontSize12;
				text-transform: uppercase;
				display: block;
				text-align: left;
				height: 18px;
				padding: 0px 20px;
			}
			.listing {
				height: calc(100vh - 520px) !important;
				display: block;
				ul {
					list-style: none;
					margin: 0;
					padding: 0;
					.clubRow {
						display: flex;
						flex-direction: row;
						align-items: center;
						// justify-content: space-between;
						padding: 12px 20px;
						.clubImgSec {
							height: 30px;
							width: 35px;
							margin-right: 25px;
							.clubImg {
								height: 100%;
								width: 100%;
								object-fit: contain;
							}
						}
						.rightClubSec {
							justify-content: space-between;
							display: flex;
							width: 100%;
							.txtSec {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								.boldTxt {
									color: $colorDarkBlue;
									font-size: $fontSize16;
									font-weight: 600;
									text-align: left;
								}
								.greyyTxt {
									color: $colorGrey;
									font-size: $fontSize14;
								}
							}
							.deleteClick {
								color: $colorGrey;
								font-size: $fontSize20;
							}
						}
					}
				}
			}
		}
		.toggleSec {
			height: 80px;
			// width: 320px;
			display: flex;
			align-items: center;
			position: absolute;
			bottom: 0;
			padding-left: 20px;
			cursor: pointer;
			.togglename {
				padding-left: 20px;
				padding-top: 4px;
				color: $colorGrey;
				font-size: $fontSize12;
			}
			.toggle {
				padding-top: 20px;

				.toggleLabel {
					position: relative;
					display: inline-block;
					width: 30px;
					height: 20px;
					background-color: $darkToggle;
					border-radius: 6px;
					transition: all 0.3s;
					cursor: pointer;
				}
				.toggleLabel::after {
					content: "";
					position: absolute;
					width: 22px;
					height: 22px;
					border-radius: 20%;
					background-color: $lightToggle;
					top: -1px;
					left: 0px;
					transition: all 0.3s;
				}

				.togglebox:checked + .toggleLabel::after {
					background-color: $bgYellow;
					left: 8px;
				}
				.togglebox:checked + .toggleLabel {
					background-color: #bfcddb;
				}
				.togglebox {
					display: none;
				}
			}
		}
	}
	.burger-menu {
		height: 100%;
		width: 350px;
		display: inline-block;
	}
}

@media only screen and (max-width: 1024px) {
	.mainSide {
		position: absolute;
		display: block;
	}
	.lineIcon {
		display: block;
		z-index: 99;
		position: fixed;
		padding: 10px 20px;
		.line {
			width: 35px;
			height: 5px;
			background-color: $bgWhite;
			margin: 6px 0;
			display: block;
		}
	}
	.CustomMenuBar {
		width: 0;
		overflow: hidden;
		&.barclose {
			width: 330px;
			transition: width 0.5s ease;
			-webkit-transition: width 0.5s; /* Safari */
			transition: width 0.5s ease;
		}
		.sideBar {
			.logoSec {
				.logo {
					&.small {
						height: 45px;
						width: 70px;
						margin: 0;
					}
				}
				.closeIcon {
					display: block;
					color: $colorDarkGrey;
					font-size: 20px;
					margin-left: 30px;
				}
			}
			.menuBar {
				.greyTxt {
				}
				ul {
					.simple {
						&.active {
							span {
							}
						}
					}
				}
			}
			.clubListing {
				.greyTxt {
				}
				.listing {
					height: calc(100vh - 420px) !important;
					display: block;
					ul {
						.clubRow {
							.clubImgSec {
							}
							.rightClubSec {
								.txtSec {
								}
							}
						}
					}
				}
			}
			.toggleSec {
				display: none;
			}
		}
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
	.CustomMenuBar {
		.sideBar {
			.clubListing {
				.listing {
					height: calc(100vh - 580px) !important;
				}
			}
		}
	}
}
// @media only screen and (min-width: 481px) and (max-width: 767px) {
// }

// @media only screen and (max-width: 767px) {
// }

// @media only screen and (max-width: 480px) {
// }

// @media only screen and (max-width: 479px) {
// }
