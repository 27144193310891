@import "../../assets/scss/variables.scss";
.resetPage {
	width: 100%;
	position: relative;
	overflow: hidden;
	.wholePageLoader {
		display: flex;
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background: rgba(255, 255, 255, 0.5);
		z-index: 9999;
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.innerPage {
		width: 100%;
		height: 100%;
		float: left;
		.resetright {
			width: 55%;
			float: left;
			.imageSection {
				// background: url("../../assets/images/back_reset.svg");
				width: 100%;
				height: 100%;
			}
		}
		.resetleft {
			width: 45%;
			float: left;
			height: 100vh;
			.formSection {
				display: flex;
				justify-content: center;
				// align-items: center;
				height: 100%;
				padding: 45px 0 20px 0;

				.mainForm {
					width: 100%;
					max-width: 400px;

					.logo {
						padding-bottom: 100px;
						.logoMain {
							width: 242px;
							height: 92px;
							display: inline-block;
						}
						.logoTxt {
							color: $colorYellow;
							font-size: $fontSize16;
							margin-bottom: 0;
						}
					}
					.bigTxtsec {
						display: block;
						margin-bottom: 40px;
						span {
							color: $colorDarkBlue;
							text-align: left;
							font-family: $secondaryFont;
							font-size: $fontSize32;
							line-height: 40px;
							display: block;
							letter-spacing: 1px;
							margin-bottom: 20px;
						}
						.titleText {
							color: $colorGrey;
							font-size: 12px;
							text-align: left;
							margin: 8px 0 0px 0;
						}
					}

					.checkbox-Forgot {
						display: flex;
						justify-content: flex-end;
						padding: 7px 0 20px 0;

						.forgotLink {
							a {
								font-size: $fontSize16;
								color: $colorBlue;
							}
							a:hover {
								color: $colorBlue;
							}
						}
					}
				}
				.bottomLink {
					display: block;
					margin-top: 100px;

					p {
						font-size: $fontSize16;
						color: $colorGrey;
					}
					.blueLink {
						cursor: pointer;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.resetPage {
		.innerPage {
			.resetright {
				width: 100%;
			}
			.resetleft {
				width: 100%;
				position: absolute !important;
				z-index: 9;

				.formSection {
					align-items: center;
					padding: 0;
					.mainForm {
						max-width: 496px;
						background: $bgWhite;
						padding: 40px 76px;
						box-shadow: 0px 2px 12px -6px $colorBlack;
						// position: absolute;
						// z-index: 9;
					}
				}
			}
		}
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.resetPage {
		.innerPage {
			.resetright {
				width: 100%;
			}
			.resetleft {
				width: 100%;
				position: absolute !important;
				z-index: 9;

				.formSection {
					align-items: center;
					padding: 0;
					.mainForm {
						max-width: 496px;
						background: $bgWhite;
						padding: 40px 76px;
						box-shadow: 0px 2px 12px -6px $colorBlack;
						// position: absolute;
						// z-index: 9;
					}
				}
			}
		}
	}
}
@media  (min-width: 320px) and (max-width: 480px){
	.resetPage {
		.innerPage {
			.resetright {
				width: 100%;
			}
			.resetleft {
				width: 100%;
				position: absolute !important;
				z-index: 9;

				.formSection {
					align-items: center;
					padding: 0;
					.mainForm {
						.logo{
								padding-bottom: 20px;
								.logoMain{
									height:70px;
									img{
										height: 100%;
										width: 100%;
										object-fit: contain;
									}
								}
						}
						
						.bigTxtsec{
							margin-bottom: 20px;
							span{
								margin-bottom: 10px;
							}
						}
						
						max-width: 340px;
						background: $bgWhite;
						padding: 40px 26px;
						box-shadow: 0px 2px 12px -6px $colorBlack;
						// position: absolute;
						// z-index: 9;
					}
				}
			}
		}
	}
  }