@import "../../assets/scss/variables.scss";
.ContactmodalMain {
  position: relative;
  display: flex;
  flex-direction: column;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .uploadSec {
    }

    .formSec {
      margin: 20px 0 10px 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      .textField {
        margin-bottom: 40px;
        .form-group {
          .errorMsg {
            position: absolute;
          }
        }

        .form-group {
          .inputBox {
            .countryPhoneGroup {
              .react-tel-input {
                width: 24%;
              }
              input {
                width: 88%;
              }
            }
            .countryPhoneGroup ~ .errorMsg {
              left: 21%;
            }
          }
        }
      }
      .colorSec {
        display: flex;
        align-items: center;
        position: relative;
        label {
          font-size: 13px;
          color: $colorGrey;
          display: inline-block;
          margin: 0;
          i {
            color: $colorLightRed;
            padding-left: 2px;
          }
        }
      }
      .btnGroup {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .submitBtn {
          width: 35%;
          height: 40px;
        }
        .cancelBtn {
          width: 35%;
          height: 40px;
          margin-left: 30px;
        }
      }
    }
  }
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .ContactmodalMain {
    form {
      .formSec {
        .textField {
          .form-group {
            .inputBox {
              .countryPhoneGroup ~ .errorMsg {
                left: 21%;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .ContactmodalMain {
    form {
      .formSec {
        .textField {
          .form-group {
            .inputBox {
              .countryPhoneGroup ~ .errorMsg {
                left: 21%;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .ContactmodalMain {
    form {
      .formSec {
        .textField {
          .inputBox {
            .countryPhoneGroup {
              .react-tel-input {
                .flag-dropdown {
                  .selected-flag {
                    .flag {
                      .arrow {
                        left: 56px;
                      }
                    }
                  }

                  &:hover {
                    .selected-flag {
                      background: transparent;
                    }
                  }

                  &:focus {
                    .selected-flag {
                      background: transparent;
                    }
                  }
                }
              }
            }
            .countryPhoneGroup ~ .errorMsg {
              left: 21%;
            }
          }
        }
      }
    }
  }
}
// @media only screen and (min-width: 993px) and (max-width: 1024px) {
//   .ContactmodalMain {
//     .leftSec {
//       .uploadArea {
//         .txtDropzone {
//           .txtSec {
//             width: 70%;
//           }
//         }
//       }
//     }
//     .rightSec {
//       form {
//         width: 82%;
//       }
//       .formSec {
//         margin: 30px 0;
//         display: flex;
//         flex-direction: column;
//         .textField {
//           margin-bottom: 30px;
//         }
//         .btnGroup {
//           .submitBtn {
//             width: 45%;
//             height: 40px;
//           }
//           .cancelBtn {
//             width: 45%;
//             height: 40px;
//             margin-left: 30px;
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 768px) and (max-width: 992px) {
//   .ContactmodalMain {
//     .leftSec {
//       .uploadArea {
//         .txtDropzone {
//           .txtSec {
//             width: 70%;
//           }
//         }
//       }
//     }
//     .rightSec {
//       form {
//         width: 82%;
//       }
//       .formSec {
//         margin: 30px 0;
//         display: flex;
//         flex-direction: column;
//         .textField {
//           margin-bottom: 30px;
//         }
//         .btnGroup {
//           .submitBtn {
//             width: 45%;
//             height: 40px;
//           }
//           .cancelBtn {
//             width: 45%;
//             height: 40px;
//             margin-left: 30px;
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (min-width: 480px) and (max-width: 767px) {
//   .ContactmodalMain {
//     flex-direction: column;
//     .leftSec {
//       width: 100%;
//       margin: 0px 0px;

//       .uploadArea {
//         .txtDropzone {
//           margin-bottom: 20px;
//           .txtSec {
//             width: 60%;
//           }
//         }
//       }
//       .dropDownSec {
//         width: 100%;
//       }
//     }
//     .divider {
//       display: none;
//     }
//     .rightSec {
//       width: 100%;
//       border: none;
//       form {
//         width: 100%;
//       }
//       .formSec {
//         margin: 10px 0;
//         display: flex;
//         flex-direction: column;
//         .textField {
//           margin-bottom: 30px;
//         }
//         .btnGroup {
//           .submitBtn {
//             width: 30%;
//             height: 40px;
//           }
//           .cancelBtn {
//             width: 30%;
//             height: 40px;
//             margin-left: 30px;
//           }
//         }
//       }
//     }
//   }
// }
