@import "../../assets/scss/variables.scss";

.modal {
	.modal-dialog {
		.modal-content {
			border-radius: 12px;
			.modal-header {
				padding: 14px 18px;
				align-items: center;
				position: relative;
				border-bottom: none;
				.headerView {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					position: relative;

					.redTxt {
						font-size: $fontSize18;
						font-weight: 500;
						color: $colorLightRed;
					}
					.blackTxt {
						font-size: $fontSize18;
						font-weight: 500;
						color: $colorDarkBlue;
					}
					.closeBtn {
						display: block;
						font-weight: 200;
						font-size: $fontSize24;
						color: $colorDarkGrey;
						position: relative;
						.close {
							top: 0px;
							width: 24px;
							height: 22px;
							opacity: 1;
							margin: 0;
							padding: 0;
						}
						.close:before,
						.close:after {
							position: absolute;
							left: 15px;
							content: " ";
							height: 24px;
							width: 2px;
							background-color: $colorDarkGrey;
						}
						.close:before {
							transform: rotate(45deg);
						}
						.close:after {
							transform: rotate(-45deg);
						}
					}
				}
			}
			.modal-body {
				padding: 0;
				.bodyView {
					padding: 20px 18px;
				}
			}
		}
	}
	&.deleteModal {
		.modal-dialog {
			max-width: 420px;

			.modal-content {
				width: 420px;

				.modal-header {
					border-bottom: 1px solid $colorBorderBlue !important;
				}
				.modal-body {
					.bodyView {
						padding: 0px;
					}
				}
			}
		}
	}
	&.clubModal {
		.modal-dialog {
			max-width: 340px;
			.modal-content {
				width: 340px;
			}
		}
	}
	&.userModal {
		.modal-dialog {
			max-width: 1000px;
			.modal-content {
				width: 1000px;
			}
		}
	}
	&.sponsersModal {
		.modal-dialog {
			max-width: 1000px;
			.modal-content {
				width: 1000px;
			}
		}
	}
	&.homepageModal {
		.modal-dialog {
			max-width: 450px;
			.modal-content {
				width: 450px;
			}
		}
	}
	&.contactModal {
		.modal-dialog {
			max-width: 450px;
			.modal-content {
				width: 450px;
			}
		}
	}
}
// default scrollbar css //
@media only screen and (min-width: 993px) and (max-width: 1024px) {
	.modal {
		&.userModal {
			.modal-dialog {
				max-width: 90%;
				.modal-content {
					width: 100%;
				}
			}
		}
		&.sponsersModal {
			.modal-dialog {
				max-width: 96%;
				.modal-content {
					width: 100%;
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
	.modal {
		&.userModal {
			.modal-dialog {
				max-width: 90%;
				.modal-content {
					width: 100%;
				}
			}
		}
		&.sponsersModal {
			.modal-dialog {
				// max-width: 90%;
				max-width: 75%;
				.modal-content {
					width: 100%;
				}
			}
		}
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.modal {
		&.userModal {
			.modal-dialog {
				max-width: 90%;
				.modal-content {
					width: 100%;
				}
			}
		}
		&.sponsersModal {
			.modal-dialog {
				max-width: 85%;
				.modal-content {
					width: 100%;
				}
			}
		}
	}
}
