@import "../../assets/scss/variables.scss";
.dashboardPage {
	width: 100%;
	position: relative;

	.innerPage {
		width: 100%;
		height: 100vh;
		padding-top: 70px !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		.ovalyellowSec {
			height: 290px;
			background: url("../../assets/images/back_yellowOval.png");
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			margin-top: -80px;
			position: relative;
			.whiteTxt {
				color: $colorWhite;
				font-size: $fontSize16;
				text-align: left;
				display: block;
				padding-left: 120px;
				width: 100%;
				position: absolute;
				top: 40%;
			}
		}
		.mainSec {
			display: flex;
			max-width: 1420px;
			margin: 0 auto;
			position: relative;
			.centerBigSec {
				display: flex;
				flex-direction: row;
				margin-top: -40px;
				.whiteSec {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 40px;
					border-radius: 90px;
					background: $bgWhite;
					box-shadow: 0px 2px 16px 0px #f6f6f6;
					cursor: pointer;
					&:nth-child(1) {
						margin-right: 33px;
					}
					&:nth-child(2) {
						margin-left: 33px;
					}
					.imgSec {
						height: 165px;
						width: 365px;
						img {
							height: 100%;
							width: 100%;
						}
					}
					.txtSec {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
						width: 90%;
						margin-top: 30px;
						h2 {
							color: $colorYellow;
							margin: 0;
							font-family: $secondaryFont;
							font-size: 48px;
						}
						.nextBtn {
							border-radius: 15px;
							padding: 18px;
							background: $bgDarkBlue;
							i {
								font-size: $fontSize22;
								color: $colorWhite;
							}
						}
					}
				}
			}
		}
		.footerTxt {
			color: $colorYellow;
			font-size: $fontSize16;
			text-align: left;
			display: block;
			padding-left: 120px;
			width: 100%;
			position: absolute;
			bottom: 30px;
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
	.dashboardPage {
		.innerPage {
			.mainSec {
				.centerBigSec {
					.whiteSec {
						&:nth-child(1) {
							margin-right: 28px;
						}
						&:nth-child(2) {
							margin-left: 28px;
						}
						.imgSec {
							height: 150px;
							width: 350px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.dashboardPage {
		.innerPage {
			.ovalyellowSec {
			}
			.mainSec {
				.centerBigSec {
					.whiteSec {
						&:nth-child(1) {
							margin-right: 18px;
						}
						&:nth-child(2) {
							margin-left: 18px;
						}
						padding: 20px;
						.imgSec {
							height: 120px;
							width: 310px;
						}
					}
				}
			}
			.footerTxt {
				bottom: 60px;
			}
		}
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.dashboardPage {
		.innerPage {
			.mainSec {
				.centerBigSec {
					.whiteSec {
						&:nth-child(1) {
							margin-right: 18px;
						}
						&:nth-child(2) {
							margin-left: 18px;
						}
						padding: 18px;
						.imgSec {
							height: 70%;
							width: 80%;
						}
						.txtSec {
							margin-top: 15px;
							justify-content: space-around;
							h2 {
								font-size: 40px;
							}
							.nextBtn {
								padding: 12px;
							}
						}
					}
				}
			}
			.footerTxt {
				bottom: 60px;
			}
		}
	}
}
