@import "../../assets/scss/variables.scss";
.nodataFound {
    height: 100vh;
    display: block;
    // .upperLogo {
    //     height: 90px;
    //     display: block;
    //     padding-top: 20px;
    //     img {
    //         object-fit: contain;
    //         height: 100%;
    //         width: 100%;
    //     }
    //     .logoTxt {
    //         color: $colorYellow;
    //         font-size: $fontSize16;
    //         margin-bottom: 0;
    //     }
    // }
    .centerPart {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0px 10px;
        width: 620px;
        margin: 0 auto;
        .txtLogo {
            height: 100px;
            display: block;
            margin-bottom: 65px;
            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
            .logoTxt {
                color: $colorYellow;
                font-size: $fontSize16;
                margin-bottom: 0;
            }
        }

        .boldText {
            font-weight: 700;
            color: $colorBlack;
            font-size: $fontSize28;
            font-family: $secondaryFont;
            margin: 0;
            padding-bottom: 4px;
        }
        .simpleText {
            color: $colorBlack;
            font-size: $fontSize16;
            margin: 0;
        }
        .applinkSec {
            padding-top: 40px;
            width: 100%;
            .appLine {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                width: 100%;
                a {
                    display: inline-block;
                }
                img {
                    height: 60px;
                    object-fit: contain;
                }
                .link {
                    font-weight: 700;
                    color: $colorBlack;
                    font-size: $fontSize16;
                    margin: 0;
                    cursor: pointer;
                    text-decoration: underline !important;
                    padding-left: 60px;
                    // width: 80%;
                    text-align: left;
                }
            }
        }
    }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .nodataFound {
        .upperLogo {
            height: 60px;
        }
        .centerPart {
            height: 94%;
            width: 90%;
            .txtLogo {
                height: 60px;
            }
            .boldText {
                font-size: $fontSize22;
            }
            .simpleText {
                font-size: $fontSize16;
            }
            .applinkSec {
                .link {
                    font-size: $fontSize16;
                }
            }
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 481px) {
    .nodataFound {
        .centerPart {
            height: 70% !important;
            width: 90% !important;
            .txtLogo {
                height: 70px;
            }

            .boldText {
                font-size: $fontSize22;
            }
            .simpleText {
                font-size: $fontSize16;
            }
            .applinkSec {
                .appLine {
                    flex-direction: column;
                    a {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
