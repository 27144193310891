@import "../../assets/scss/variables.scss";
.ViewClubPage {
	width: 100%;
	position: relative;
	float: left;
	overflow: hidden;
	.centerSec {
		width: 100%;
		float: left;
		padding-top: 60px;
		background: $bglightBlue;
		height: 100%;
		.rightMain {
			float: left;
			height: calc(100vh - 140px) !important;
			width: 100%;
			.searchSec {
				background: $bgWhite;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 12px 40px 12px 32px;
				.backICON {
					color: $colorDarkBlue;
					font-size: $fontSize20;
					padding-right: 10px;
				}
				.blackdarkTxt {
					color: $colorDarkBlue;
					font-size: $fontSize18;
					padding-right: 25px;
					font-weight: 500;
					padding-left: 10px;
				}
				.divider {
					height: 30px;
					width: 2px;
					display: block;
					background: $bgLightGrey;
					margin-right: 25px;
				}
				.searchMain {
					width: 260px;
				}
			}
			.clublisting {
				height: calc(100vh - 200px) !important;
				// background: red;
				.pageinnerscroll {
					padding: 40px 60px 40px 60px;
					display: block;
					.listingTopSec {
						display: flex;
						justify-content: space-between;
						align-items: center;
						background: $bgWhite;
						border-radius: 20px;
						height: 95px;
						.backImgSec {
							background: url("../../assets/images/dottedBg.png");
							height: 100%;
							width: 30%;
							background-size: cover;
							border-radius: 20px 0 20px 20px;
							position: relative;
							.imgCircle {
								background: $bgWhite;
								width: 105px;
								height: 105px;
								border-radius: 50%;
								display: flex;
								align-items: center;
								justify-content: center;
								position: absolute;
								position: absolute;
								bottom: -50%;
								left: 50%;
								-webkit-transform: translateX(-50%);
								transform: translateX(-50%);
								border: 1px solid $imgroundBorder;
								.imgDiv {
									height: 65px;
									width: 65px;

									.clublogoMain {
										height: 100%;
										width: 100%;
										object-fit: contain;
									}
								}
							}
						}
						.blacTxtSec {
							width: 30%;
							text-align: left;
							padding-left: 40px;
							.blacTxt {
								color: $colorDarkBlue;
								font-size: $fontSize18;
								font-weight: 500;
							}
						}
						.greyTxtSec {
							width: 20%;
							.greyTxt {
								color: $colorGrey;
								font-size: $fontSize16;
								text-align: left;
							}
						}
					}
					.viewClub {
						margin-top: 77px;
						ul {
							display: flex;
							flex-wrap: wrap;
							list-style: none;
							justify-content: space-between;
							margin: 0;
							padding: 0;
							li {
								display: flex;
								flex-wrap: wrap;
								flex-direction: row;
								width: 31.5%;
								position: relative;
								// background: $bgWhite;
								.viewCard {
									// background: linear-gradient(0deg, #f3f6f9 40%, #fff 60%);
									background: rgba(255, 255, 255, 1);
									background: -moz-linear-gradient(
										top,
										#f3f6f9 0%,
										#f3f6f9 35%,
										$bgWhite 35%,
										$bgWhite 100%
									);
									background: -webkit-gradient(
										left top,
										left bottom,
										color-stop(0%, #f3f6f9),
										color-stop(35%, #f3f6f9),
										color-stop(35%, $bgWhite),
										color-stop(100%, $bgWhite)
									);
									background: -webkit-linear-gradient(
										top,
										#f3f6f9 0%,
										#f3f6f9 35%,
										$bgWhite 35%,
										$bgWhite 100%
									);
									background: -o-linear-gradient(
										top,
										#f3f6f9 0%,
										#f3f6f9 35%,
										$bgWhite 35%,
										$bgWhite 100%
									);
									background: -ms-linear-gradient(
										top,
										rgba(255, 255, 255, 1) 0%,
										rgba(255, 255, 255, 1) 35%,
										$bgWhite 35%,
										$bgWhite 100%
									);
									background: linear-gradient(
										to bottom,
										#f3f6f9 0%,
										#f3f6f9 35%,
										$bgWhite 35%,
										$bgWhite 100%
									);
									// background: $bgWhite;
									z-index: 99;
									border-radius: 20px;
									display: flex;
									flex-direction: column;
									align-items: center;
									overflow: hidden;
									padding: 40px 28px;
									width: 100%;
									.imgBig {
										img {
											height: 100%;
											width: 100%;
										}
									}
									.yelloBigTxt {
										font-size: $fontSize32;
										font-family: $secondaryFont;
										color: $yellowTxt;
										margin-top: 10px;
									}
									&:hover {
										box-shadow: 0px 2px 20px 0px $imgroundBorder;
									}
								}
								// .background {
								// 	position: absolute;
								// 	top: 0px;
								// 	left: 0px;
								// 	width: 100%;
								// 	height: 40%;
								// 	background-color: #f3f6f9;
								// 	z-index: -99;
								// 	border-radius: 20px 20px 0 0;
								// }
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.ViewClubPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 180px) !important;
				// background: red;
				.clublisting {
					height: calc(100vh - 260px) !important;

					.pageinnerscroll {
						padding: 40px 30px 40px 30px;
						.viewClub {
							ul {
								li {
									.viewCard {
										.imgBig {
											width: 60%;
										}
										.yelloBigTxt {
											font-size: $fontSize28;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.ViewClubPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 170px) !important;

				.clublisting {
					// height: calc(100vh - 240px) !important;
				}
			}
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
	.ViewClubPage {
		.centerSec {
			.rightMain {
				.searchSec {
					padding: 12px 20px 12px 18px;
				}
				.clublisting {
					.pageinnerscroll {
						padding: 40px 30px 40px 30px;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.ViewClubPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 120px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
				}
				.clublisting {
					height: calc(100vh - 240px) !important;

					.pageinnerscroll {
						padding: 40px 30px 40px 30px;
						.viewClub {
							ul {
								li {
									.viewCard {
										.imgBig {
											width: 70%;
										}
										.yelloBigTxt {
											font-size: $fontSize28;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.ViewClubPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 120px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
				}
				.clublisting {
					height: calc(100vh - 280px) !important;
					.pageinnerscroll {
						padding: 40px 30px 40px 30px;
						.viewClub {
							ul {
								li {
									.viewCard {
										.imgBig {
											width: 70%;
										}
										.yelloBigTxt {
											font-size: $fontSize26;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
