@import "../../assets/scss/variables.scss";
.deletemodalMain {
  position: relative;
  .deleteView {
    .infoView {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
      border-bottom: 1px solid $colorBorderBlue;
      p {
        margin: 0;
        text-align: center;
        font-size: $fontSize16;
        font-weight: 500;
        color: $colorDarkBlue;
        width: 80%;
      }
    }
    .btnGroup {
      display: flex;
      justify-content: space-around;
      padding: 24px 18px;
      .redBtn {
        width: 35%;
        height: 40px;
      }
      .cancelBtn {
        width: 35%;
        height: 40px;
      }
    }
  }
}
