@import "../../assets/scss/variables.scss";
.emptyData {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  span {
    display: flex;
    font-size: $fontSize18;
    font-weight: 500;
    color: $colorGrey;
    // text-transform: uppercase;
  }
}
