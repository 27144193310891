@import "../../assets/scss/variables.scss";
.submitBtn {
    background: $bgYellow;
    width: 100%;
    border: none !important;
    height: 55px;
    font-size: $fontSize16;
    font-weight: 600;
    border-radius: 20px;
    color: $colorWhite;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: none !important;
        box-shadow: none !important;
        background: $bgYellow;
        color: $colorWhite;
    }
    &:active {
        box-shadow: none !important;
        background: $bgYellow;
        color: $colorWhite;
        background-color: $colorYellow;
    }
    &:hover {
        // background: $colorGreyBlack;
        background: $bgYellow;
        color: $colorWhite;
        // color: $colorGreen;
    }
    &:disabled {
        background: $bgGrey;
        color: $colorWhite;
    }
}
.cancelBtn {
    background: $bgWhite;
    color: $colorGrey;
    width: 100%;
    border: 1px solid $colorBorderBlue !important;
    height: 55px;
    font-size: $fontSize16;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: none !important;
        box-shadow: none !important;
        background: $bgWhite;
        color: $colorGrey;
    }
    &:active {
        box-shadow: none !important;
        background: $bgWhite !important;
        color: $colorGrey !important;
    }
    &:hover {
        background: $bgWhite;
        color: $colorGrey;
    }
    // &:disabled {
    //     background: $bgGrey;
    //     color: $colorWhite;
    // }
}
.redBtn {
    background: $bglightred;
    color: $colorWhite;
    width: 100%;
    border: 1px solid $bglightred !important;
    height: 55px;
    font-size: $fontSize16;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: none !important;
        box-shadow: none !important;
        background: $bglightred;
        color: $colorWhite;
    }
    &:active {
        box-shadow: none !important;
        background: $bglightred !important;
        color: $colorWhite !important;
    }
    &:hover {
        background: $bglightred !important;
        color: $colorWhite !important;
    }
    // &:disabled {
    //     background: $bgGrey;
    //     color: $colorWhite;
    // }
}
.btn-primary:not(:disabled):not(.disabled):active {
    background: $bgYellow;
    color: $colorWhite;
}
