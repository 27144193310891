// Bg Color
$bglight: #e5e5e5;
$bgWhite: #ffffff;
$bgBlack: #000000;
$bgYellow: #feb202;
$bgLightDarkYellow: #ffeeb5;
$bgLightGrey: #f3f6f9;
$bgDarkGrey: #939597;
$bgLightYellow: #fff5d4;
$bgDarkBlue: #293d4e;
$bgGrey: #b4b4b4;
$bglightBlue: #f5f9fa;
$bglightred: #f64e60;
$bgCyan: #c9f7f5;

// Color
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorBlue: #2979ff;
$colorGrey: #99adc4;
$colorDarkBlue: #293d4e;
$colorBorderBlue: #dfe6ee;
$colorYellow: #ff9f00;
$colorDarkYellow: #d89906;
$colorLightYellow: #ffeeb5;
$colorGreendark: #089782;
$colorLightRed: #f64e60;
$colorRed: #ff5353;
$colorGreyBlack: #2d2f32;
$colorDarkGrey: #575757;
$colorDarkCyan: #1bc5bd;
$bgPagination: #f3f6f9;
$imgroundBorder: #c8d7e6;
$noPagination: #f0ac33;
$yellowTxt: #feb202;
$lightToggle: #bfcddb;
$darkToggle: #b8b8b8;

// Font Size
$fontSize8: 8px;
$fontSize10: 10px;
$fontSize12: 12px;
$fontSize13: 13px;
$fontSize14: 14px;
$fontSize15: 15px;
$fontSize16: 16px;
$fontSize18: 18px;
$fontSize20: 20px;
$fontSize22: 22px;
$fontSize24: 24px;
$fontSize26: 26px;
$fontSize28: 28px;
$fontSize32: 32px;
$fontSize40: 40px;

$primaryFont: "SF UI Text";
$secondaryFont: "Rockwell";

//css
.padding0 {
	padding: 0;
}
.padding15 {
	padding: 15px;
}

.paddingTop {
	padding-top: 64px;
}
.paddLeft {
	padding-left: 15px;
}
.paddRight {
	padding-right: 15px;
}
.paddLeft0 {
	padding-left: 0px;
}
.paddRight0 {
	padding-right: 0px;
}
.margin0 {
	margin: 0;
}
.margin15 {
	margin: 15px;
}
.marLeft {
	margin-left: 15px;
}
.marRight {
	margin-right: 15px;
}
.marLeft0 {
	margin-left: 0px;
}
.marRight0 {
	margin-right: 0px;
}
a {
	text-decoration: none !important;
}
