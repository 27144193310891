@import "../../assets/scss/variables.scss";
.headerMain {
	display: block;
	width: 100%;
	float: left;
	.topHeaderSec {
		padding: 10px 30px;
		width: 100%;
		left: 0;
		top: 0;
		position: fixed;
		background: $bgYellow;
		display: flex;
		justify-content: space-between;
		height: 62px;
		z-index: 11;
		&::after {
			border: 0.01em solid $colorDarkYellow;
			display: block;
			content: "";
			position: absolute;
			bottom: 0px;
			width: 100%;
			left: 0;
		}
		.logo {
			display: flex;
			align-items: center;
			.logoVisble {
				display: flex;
				align-items: center;
				.logoMain {
					// width: 82px;
					// height: 50px;
					display: inline-block;
					margin-right: 10px;
				}
				.logoTxt {
					color: $colorWhite;
					font-size: $fontSize16;
					margin-bottom: 0;
				}
			}
		}
		.buttonSec {
			display: flex;
			align-items: center;
			position: relative;
			a {
				text-transform: uppercase;
				padding: 10px 18px;
				font-size: $fontSize13;
				border-radius: 15px;
			}
			.homeIcon {
				display: flex;
				cursor: pointer;
				position: relative;
				i {
					color: $colorDarkYellow;
					font-size: $fontSize22;
				}
				span {
					position: absolute;
					bottom: 0;
					left: 10%;
				}
				.borderBottom {
					&::after {
						display: block;
						content: "";
						position: absolute;
						height: 6px;
						width: 50px;
						bottom: -15px;
						left: 10%;
						background: $colorDarkYellow;
					}
				}
			}
			.profileBtn {
				border: 1px solid $colorDarkGrey;
				margin-left: 20px;
				color: $colorDarkGrey;
				font-weight: 600;
			}
			.logoutBtn {
				color: $colorWhite;
				margin-left: 20px;
				box-shadow: 10px 15px 20px -12px #cb8e00, -1px -5px 11px 0px #fcc649;
				-moz-box-shadow: 10px 15px 20px -12px #cb8e00, -1px -5px 11px 0px #fcc649;
				-webkit-box-shadow: 10px 15px 20px -12px #cb8e00, -1px -5px 11px 0px #fcc649;
			}
		}
	}
	.wholePageLoader {
		display: flex;
		position: fixed;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background: rgba(255, 255, 255, 0.5);
		z-index: 9999;
		height: 100%;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.headerMain {
		.topHeaderSec {
			padding: 10px 18px 10px 20px;
		}
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.headerMain {
		.topHeaderSec {
			padding: 10px 20px;
			.logo {
				.logoVisble {
					.logoMain {
						margin-right: 6px;
					}
					.logoTxt {
						font-size: $fontSize14;
					}
				}
			}
			.buttonSec {
				.logoutBtn {
					margin-left: 14px;
				}
			}
		}
	}
}
