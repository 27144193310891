@import "../../assets/scss/variables.scss";
.toast {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  right: -100%;
  margin: 0 auto;
  position: absolute;
  top: 40px !important;
  background: $bgWhite;
  background-image: none;
  border: none;
  box-shadow: none;
  padding: 14px 24px;
  max-width: 400px;
  border-radius: 50px 0 0 50px;
  z-index: 11;
  cursor: pointer;
  // transform: translateX(100%);
  // -webkit-transform: translateX(100%);
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  &.success-txt{
    background-image: linear-gradient(to right, #1bc5bd, #06ce81);
    -webkit-box-shadow: 0px 2px 8px 0px #04b47d;
  -moz-box-shadow: 0px 2px 8px 0px #04b47d;
  box-shadow: 0px 2px 8px 0px #04b47d;
  }
  &.error-txt {
    background-image: linear-gradient(to right, #ff6565, #db0a3a);
    -webkit-box-shadow: 0px 2px 8px 0px #b40404;
    -moz-box-shadow: 0px 2px 8px 0px #b40404;
    box-shadow: 0px 2px 8px 0px #b40404;
    .closeView {
      color: $colorWhite;
    }
  }
  &.show {
    display: inline-flex;
    opacity: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40px !important;
    right: 0%;
    // transform: translateX(0%);
    // -webkit-transform: translateX(0%);
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
  }
  .iconView {
    display: flex;   
   padding-right: 14px;  
   align-items: center; 
   justify-content: center;
    .iconTick {
      font-weight: 400;
      font-size: $fontSize24;
      color: $colorWhite;
    }
    .iconTickSmall{
      font-weight: 400;
      font-size: $fontSize20;
      color: $colorWhite;
    }
  }
  .contentView {
    display: block;
    text-align: left;
    padding-right: 10px;
    .boldTxt {
      font-size: $fontSize16;
      font-weight: 600;
      margin-bottom: 4px;
    }
    .simpleTxt {
      font-size: $fontSize14;
    }
    p {
      margin: 0px;
      color: $colorWhite;
    }
  }
  .closeView {
    color: $colorGreendark;
    a {
      font-size: $fontSize18;
    }
  }

  // &.errormassage {
  //   p {
  //     // color: $colorRed;
  //   }
  // }

  @media (max-width: 480px) {
    max-width: 300px;
    padding: 12px 14px;
  }
}
