@import "../../assets/scss/variables.scss";

.imageView {
	position: relative;

	.carousel {
		.carousel-indicators {
			display: none;
		}
		.carousel-inner {
			div {
				img {
					height: 100vh;
					object-fit: cover;
				}
			}
		}
		.carousel-control-prev {
			left: auto;
			right: 210px;
			opacity: 1;
			top: 70%;
			width: auto;
			height: 100px;
		}
		.carousel-control-next {
			opacity: 1;
			top: 70%;
			right: 110px;
			width: auto;
			height: 100px;
		}
	}
	.rightNoswitchSec {
		display: flex;
		flex-direction: column;
		// height: 60%;
		justify-content: space-between;
		align-items: flex-end;
		position: absolute;
		right: 160px;
		top: 15%;
		.noSec {
			display: flex;
			flex-direction: row;
			align-items: center;
			position: relative;
			.bigTxt {
				font-size: 80px;
				color: $colorDarkBlue;
				font-family: $secondaryFont;
				font-weight: bold;
			}
			.smalltxt {
				font-size: $fontSize24;
				color: $colorYellow;
				padding-left: 10px;
			}
			.border {
				background: $colorBorderBlue;
				height: 10px;
				width: 115px;
				position: absolute;
				bottom: 0;
				border-radius: 8px;
			}
		}
	}
}

a {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.whiteBtn {
	padding: 12px;
	border-radius: 50px;
	border: 3px solid $colorWhite;
	margin-right: 10px;
	i {
		color: $colorWhite;
		font-weight: bold;
	}
}
.yellowBtn {
	padding: 20px;
	border-radius: 50px;
	border: 5px solid $colorYellow;
	margin-left: 10px;
	.right {
		color: $colorYellow;
		font-weight: bold;
		transform: rotate(180deg);
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.imageView {
		.carousel {
			.carousel-control-prev {
				right: 110px;
				opacity: 1;
				top: 88%;
				height: 100px;
			}
			.carousel-control-next {
				opacity: 1;
				top: 88%;
				right: 30px;
				height: 100px;
			}
		}
		.rightNoswitchSec {
			right: 7%;
			top: 1%;
		}
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.imageView {
		.carousel {
			.carousel-control-prev {
				right: 110px;
				opacity: 1;
				top: 88%;
				height: 100px;
			}
			.carousel-control-next {
				opacity: 1;
				top: 88%;
				right: 30px;
				height: 100px;
			}
		}
		.rightNoswitchSec {
			right: 7%;
			top: 0%;
		}
	}
}
