@import "../../assets/scss/variables.scss";
.footerMain {
	display: block;
	width: 100%;
	float: left;
	.footerArea {
		height: 80px;
		width: 100%;
		display: flex;
		align-items: center;
		border-top: 5px solid $bgYellow;
		position: fixed;
		bottom: 0;
		padding-left: 32px;
		background: $bgWhite;
		.footertxt {
			color: $colorGrey;
			font-size: $fontSize14;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.footerMain {
		.footerArea {
			height: 60px;
		}
	}
}
