@import "../../assets/scss/variables.scss";
.UsersListingPage {
	width: 100%;
	position: relative;
	float: left;
	overflow: hidden;
	height: 100%;
	.centerSec {
		width: 100%;
		float: left;
		padding-top: 60px;
		background: $bglightBlue;
		height: 100%;

		.rightMain {
			float: left;
			height: calc(100vh - 140px) !important;
			width: 100%;
			.searchSec {
				background: $bgWhite;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 12px 40px 12px 32px;
				.leftSec {
					display: flex;
					flex-direction: row;
					align-items: center;
					.blackdarkTxt {
						color: $colorDarkBlue;
						font-size: $fontSize18;
						padding-right: 15px;
						font-weight: 500;
					}
					.divider {
						height: 30px;
						width: 2px;
						display: block;
						background: $bgLightGrey;
						margin-right: 15px;
					}
					.greyTxt {
						color: $colorGrey;
						padding-right: 40px;
						font-size: $fontSize16;
					}
					.searchMain {
						width: 260px;
					}
				}
				.rightBtn {
					display: flex;
					.cyanBtn {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px 28px;
						background: $bgCyan;
						border-radius: 15px;
						font-size: $fontSize12;
						color: $colorDarkCyan;
						i {
							font-size: $fontSize16;
							margin-right: 8px;
						}
					}
				}
			}
			.userlisting {
				margin: 30px 50px 15px 50px;
				background: $bgWhite;
				border-radius: 10px;
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.034);
				padding: 22px 28px;
				.listingTopSec {
					display: flex;
					justify-content: space-between;
					align-items: center;
					// padding-right: 20px;
					.txtSec {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						.blacTxt {
							color: $colorDarkBlue;
							font-size: $fontSize18;
							font-weight: 500;
						}
						.greyTxt {
							color: $colorGrey;
							font-size: $fontSize16;
							text-align: left;
						}
					}
					.btnSec {
						width: 120px;
						.yellowExportBtn {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 10px 22px;
							background: $bgLightDarkYellow;
							border-radius: 20px;
							font-size: $fontSize12;
							color: $colorYellow;
							i {
								font-size: $fontSize16;
								margin-right: 8px;
							}
						}
					}
				}
				.userHeader {
					padding: 0px 0px 20px 0px;
					display: block;
					margin-top: 14px;
					border-bottom: 1px solid $colorBorderBlue;
					ul {
						list-style-type: none;
						padding: 0px;
						margin: 0px;
						display: flex;
						align-items: center;
						li {
							text-align: left;
							color: $colorGrey;
							font-size: $fontSize16;
							font-weight: 600;
						}
						.yellowIcons {
							width: 6%;
							color: $colorYellow;
							display: flex;
							align-items: center;
						}
						.userDetailsHead {
							display: none;
						}
						.nameHead {
							width: 27%;
							display: flex;
							i {
								margin-left: 6px;
								transform: rotate(90deg);
								font-size: $fontSize14;
								color: $colorYellow;
								&.upArrow {
									transform: rotate(270deg);
								}
							}
						}
						.phoneHead {
							width: 18%;
						}
						.clubsHead {
							width: 32%;
						}
						.actionsHead {
							width: 14%;
						}
					}
				}
				.sortDiv {
					display: none;
				}
				.paginationSec {
					height: 42px;
				}
				.usersScroll {
					height: calc(100vh - 460px) !important;
					// margin: 20px 0px;
					ul {
						display: flex;
						flex-wrap: wrap;
						list-style: none;
						margin: 0;
						padding: 0;

						.userRow {
							background-color: white;
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
							align-items: center;
							width: 100%;
							border-bottom: 1px solid $colorBorderBlue;
							padding: 20px 0;

							.blueTxt {
								font-size: $fontSize16;
								font-weight: 500;
								color: $colorDarkBlue;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								display: block;
								padding-right: 4px;
							}
							.noRow {
								width: 6%;
								text-align: left;
								font-size: $fontSize16;
								font-weight: 500;
								color: $colorYellow;
								position: relative;
							}
							.userImgRow {
								width: 27%;
								display: flex;
								flex-direction: row;
								align-items: flex-start;
								position: relative;
								.imgMain {
									height: 50px;
									width: 50px;
									margin-right: 20px;
									border-radius: 10px;
									.useIMG {
										width: 100%;
										height: 100%;
										object-fit: contain;
										border-radius: 10px;
									}
								}
								.infoSec {
									text-align: left;
									width: 70%;

									.mailTxt {
										color: $colorGrey;
										white-space: nowrap;
										overflow: hidden;
										text-overflow: ellipsis;
										margin-bottom: 0;
									}
									.blueMobTxt {
										display: none;
									}
								}
							}
							.mobRow {
								width: 18%;
								text-align: left;
								position: relative;
							}
							.clubsRow {
								width: 32%;
								text-align: left;
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: space-between;
								padding-right: 50px;
								position: relative;
								.cyanBox {
									padding: 3px 8px;
									border-radius: 8px;
									display: block;
									text-align: center;
									background: $bgCyan;
									color: $colorDarkCyan;
									font-size: $fontSize10;
								}
							}
							.actionsRow {
								width: 14%;
								text-align: left;
								display: flex;
								flex-direction: row;
								align-items: center;
								.clickBtn {
									padding: 12px;
									background: $bgPagination;
									border-radius: 12px;
									margin-right: 18px;
									height: 46px;
									width: 46px;
									.tooltipSmall {
										padding: 12px;
									}
									.round {
										display: flex;
										align-items: center;
										justify-content: center;
										padding: 4px 4px;
										border-radius: 40px;
										background: #d8e0e9;
									}

									i {
										color: $colorGrey;
										font-size: $fontSize16;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1500px) {
	.UsersListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 140px) !important;

				&.sidePadding {
					.userlisting {
						.userHeader {
							display: none;
						}
						.sortDiv {
							display: flex;
							flex-direction: row;
							margin: 10px 0 0 0;
							align-items: center;
							font-size: $fontSize14;
							.linkSmall {
								margin-left: 8px;
								font-size: $fontSize16;
								color: $colorYellow;
								font-weight: 600;
								.greySmall {
									margin-left: 4px;
									color: $colorGrey;
									font-size: $fontSize14;
								}
								i {
									color: $colorYellow;
									font-size: $fontSize16;
									font-weight: 600;
									transform: rotate(90deg);
									margin-left: 4px;
									&.upArrow {
										transform: rotate(270deg);
									}
								}
							}
						}
						.usersScroll {
							// height: calc(100vh - 180px);
							ul {
								.userRow {
									.noRow {
										width: 6%;
										padding-top: 40px;
										&::before {
											content: "#";
											position: absolute;
											top: -13px;
										}
									}
									.userImgRow {
										width: 60%;
										padding-top: 40px;

										&::before {
											content: "NAME";
											position: absolute;
											top: 0;
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
										}
									}
									.mobRow {
										width: 30%;
										padding-top: 40px;
										&::before {
											content: "PHONE NUMBER";
											position: absolute;
											top: -10px;
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
										}
									}
									.clubsRow {
										width: 60%;
										position: relative;
										padding-top: 40px;
										margin-left: 6%;
										&::before {
											content: "CLUBS";
											position: absolute;
											top: 0px;
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
										}
									}
									.actionsRow {
										width: 30%;
										position: relative;
										padding-top: 40px;
										// .clickBtn {
										// 	height: 40px;
										// 	width: 40px;
										// 	.round {
										// 		padding: 3px 3px;
										// 	}
										// }
										&::before {
											content: "ACTIONS";
											position: absolute;
											top: 10px;
											color: $colorGrey;
											font-size: $fontSize16;
											font-weight: 600;
											margin-bottom: 15px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
	.UsersListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 200px) !important;
				// background: red;

				&.sidePadding {
					.userlisting {
						.userHeader {
							display: none;
						}
						.usersScroll {
							height: calc(100vh - 460px) !important;
						}
					}
				}

				.userlisting {
					margin: 30px 20px 15px 20px;
					padding: 16px 12px;
					.userHeader {
						ul {
							.yellowIcons {
								width: 4%;
							}
							.nameHead {
								width: 25%;
							}
							.phoneHead {
								width: 18%;
							}
							.clubsHead {
								width: 32%;
							}
							.actionsHead {
								width: 18%;
							}
						}
					}

					.usersScroll {
						height: calc(100vh - 520px) !important;
						ul {
							.userRow {
								.noRow {
									width: 4%;
								}
								.userImgRow {
									width: 25%;
								}
								.mobRow {
									width: 18%;
								}
								.clubsRow {
									width: 32%;
								}
								.actionsRow {
									width: 18%;
									.clickBtn {
										height: 40px;
										width: 40px;
										.round {
											padding: 3px 3px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
	.UsersListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 180px) !important;

				.userlisting {
					margin: 30px 20px 15px 14px;
					padding: 16px 12px;
					.userHeader {
						ul {
							.yellowIcons {
								width: 4%;
							}
							.nameHead {
								width: 25%;
							}
							.phoneHead {
								width: 18%;
							}
							.clubsHead {
								width: 32%;
							}
							.actionsHead {
								width: 18%;
							}
						}
					}

					.usersScroll {
						height: calc(100vh - 370px) !important;

						ul {
							.userRow {
								.noRow {
									width: 4%;
								}
								.userImgRow {
									width: 25%;
								}
								.mobRow {
									width: 18%;
								}
								.clubsRow {
									width: 32%;
								}
								.actionsRow {
									width: 18%;
									.clickBtn {
										height: 40px;
										width: 40px;
										.round {
											padding: 3px 3px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
	.UsersListingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 150px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					.leftSec {
						.blackdarkTxt {
							padding-right: 10px;
						}
						.divider {
							margin-right: 10px;
						}
						.greyTxt {
							padding-right: 10px;
						}
						.searchMain {
							width: 230px;
						}
					}
				}
				.userlisting {
					margin: 30px 20px 15px 14px;
					padding: 16px 12px;
					.userHeader {
						display: none;
					}
					.sortDiv {
						display: flex;
						flex-direction: row;
						margin: 10px 0 0 0;
						align-items: center;
						font-size: $fontSize14;
						.linkSmall {
							margin-left: 8px;
							font-size: $fontSize16;
							color: $colorYellow;
							font-weight: 600;
							.greySmall {
								margin-left: 4px;
								color: $colorGrey;
								font-size: $fontSize14;
							}
							i {
								color: $colorYellow;
								font-size: $fontSize16;
								font-weight: 600;
								transform: rotate(90deg);
								margin-left: 4px;
								&.upArrow {
									transform: rotate(270deg);
								}
							}
						}
					}
					.usersScroll {
						height: calc(100vh - 430px) !important;
						ul {
							.userRow {
								.noRow {
									width: 6%;
									padding-top: 40px;
									&::before {
										content: "#";
										position: absolute;
										top: -13px;
									}
								}
								.userImgRow {
									width: 60%;
									padding-top: 40px;
									&::before {
										content: "NAME";
										position: absolute;
										top: 0;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.mobRow {
									width: 30%;
									padding-top: 40px;
									&::before {
										content: "PHONE NUMBER";
										position: absolute;
										top: -10px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.clubsRow {
									width: 60%;
									position: relative;
									padding-top: 40px;
									margin-left: 6%;
									&::before {
										content: "CLUBS";
										position: absolute;
										top: 0px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.actionsRow {
									width: 30%;
									position: relative;
									padding-top: 40px;
									.clickBtn {
										height: 40px;
										width: 40px;
										.round {
											padding: 3px 3px;
										}
									}
									&::before {
										content: "ACTIONS";
										position: absolute;
										top: 10px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.UsersListingPage {
		height: 100vh;
		.centerSec {
			.rightMain {
				height: calc(100vh - 140px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					.leftSec {
						.blackdarkTxt {
							padding-right: 10px;
						}
						.divider {
							margin-right: 10px;
						}
						.greyTxt {
							padding-right: 10px;
						}
						.searchMain {
							width: 230px;
						}
					}
				}
				.userlisting {
					margin: 30px 20px 15px 14px;
					padding: 16px 12px;
					.userHeader {
						display: none;
					}
					.sortDiv {
						display: flex;
						flex-direction: row;
						margin: 10px 0 0 0;
						align-items: center;
						font-size: $fontSize14;
						.linkSmall {
							margin-left: 8px;
							font-size: $fontSize16;
							color: $colorYellow;
							font-weight: 600;
							.greySmall {
								margin-left: 4px;
								color: $colorGrey;
								font-size: $fontSize14;
							}
							i {
								color: $colorYellow;
								font-size: $fontSize16;
								font-weight: 600;
								transform: rotate(90deg);
								margin-left: 4px;
								&.upArrow {
									transform: rotate(270deg);
								}
							}
						}
					}
					.usersScroll {
						height: calc(100vh - 400px) !important;
						ul {
							.userRow {
								.noRow {
									width: 6%;
									padding-top: 40px;
									&::before {
										content: "#";
										position: absolute;
										top: -13px;
									}
								}
								.userImgRow {
									width: 60%;
									padding-top: 40px;
									&::before {
										content: "NAME";
										position: absolute;
										top: 0;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.mobRow {
									width: 30%;
									padding-top: 40px;
									&::before {
										content: "PHONE NUMBER";
										position: absolute;
										top: -10px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.clubsRow {
									width: 60%;
									position: relative;
									padding-top: 35px;
									margin-left: 6%;
									&::before {
										content: "CLUBS";
										position: absolute;
										top: 0;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
								.actionsRow {
									width: 30%;
									position: relative;
									padding-top: 40px;
									.clickBtn {
										height: 40px;
										width: 40px;
										.round {
											padding: 3px 3px;
										}
									}
									&::before {
										content: "ACTIONS";
										position: absolute;
										top: 10px;
										color: $colorGrey;
										font-size: $fontSize16;
										font-weight: 600;
										margin-bottom: 15px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
