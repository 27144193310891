@import "../../assets/scss/variables.scss";
.clubmodalMain {
  position: relative;
  .formSec {
    .textField {
      margin-bottom: 10px;
      margin-top: 40px;
      .form-group {
        margin-bottom: 0px;
        .inputBox {
          .errorMsg {
            position: absolute;
          }
        }
      }
    }
    .btnGroup {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .submitBtn {
        width: 45%;
        height: 40px;
        padding: 8px 42px;
      }
      .cancelBtn {
        width: 45%;
        height: 40px;
        padding: 8px 28px;
      }
    }
  }
}
