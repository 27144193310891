@import "../../assets/scss/variables.scss";
.clubslistingPage {
	width: 100%;
	position: relative;
	float: left;
	overflow: hidden;
	height: 100%;
	.centerSec {
		width: 100%;
		float: left;
		padding-top: 60px;
		background: $bglightBlue;
		height: 100%;
		.rightMain {
			float: left;
			height: calc(100vh - 140px);
			width: 100%;
			.searchSec {
				background: $bgWhite;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 12px 40px 12px 32px;
				.leftSec {
					display: flex;
					flex-direction: row;
					align-items: center;
					.blackdarkTxt {
						color: $colorDarkBlue;
						font-size: $fontSize18;
						padding-right: 15px;
						font-weight: 500;
					}
					.divider {
						height: 30px;
						width: 2px;
						display: block;
						background: $bgLightGrey;
						margin-right: 15px;
					}
					.greyTxt {
						color: $colorGrey;
						padding-right: 40px;
						font-size: $fontSize16;
					}
					.searchMain {
						width: 260px;
					}
				}
				.rightBtn {
					display: flex;
					.cyanBtn {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px 28px;
						background: $bgCyan;
						border-radius: 15px;
						font-size: $fontSize12;
						color: $colorDarkCyan;
						i {
							font-size: $fontSize20;
							margin-right: 8px;
						}
					}
				}
			}
			.clublisting {
				padding: 40px 5px 10px 60px;
				.listingTopTxtSec {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-left: 4px;
					.txtSec {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						.blacTxt {
							color: $colorDarkBlue;
							font-size: $fontSize18;
							font-weight: 500;
						}
						.greyTxt {
							color: $colorGrey;
							font-size: $fontSize16;
							text-align: left;
						}
					}
					.btnSec {
						width: 140px;
						.submitBtn {
							height: 40px;
							font-size: $fontSize12;
							position: relative;
							border-radius: 15px;
							.clubIcon {
								margin-right: 7px;
								font-size: $fontSize20;
							}
						}
					}
				}

				.txtBtnSec {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-right: 20px;
					.greyBtnSecTxt {
						color: $colorGrey;
						font-size: $fontSize16;
					}
					.submitBtn {
						height: 40px;
						font-size: $fontSize12;
						position: relative;
						border-radius: 15px;
						width: auto;
						padding: 12px 22px;
						.clubIcon {
							margin-right: 10px;
							font-size: $fontSize20;
						}
					}
				}

				.clubsScroll {
					height: calc(100vh - 330px) !important;
					margin: 20px 0px;
					ul {
						display: flex;
						flex-wrap: wrap;
						list-style: none;
						margin: 0;
						padding: 0;
						padding: 4px 0 0 4px;
						li {
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;

							.card {
								background-color: white;
								border-radius: 20px;
								display: flex;
								flex-direction: column;
								align-items: center;
								padding: 18px;
								border: 1px solid $colorBorderBlue;
								margin: 0 15px 15px 0;
								cursor: pointer;
								width: 205px;
								&:hover {
									box-shadow: 0px 2px 10px 0px $imgroundBorder;
								}

								.clubImg {
									height: 80px;
									display: block;
									.clubImgmain {
										height: 100%;
										width: 100%;
										object-fit: contain;
									}
								}

								.bottomSec {
									margin-top: 20px;
									display: flex;
									flex-direction: column;
									justify-content: space-between;
									height: 100%;
									align-items: center;
									.greycardTxt {
										display: block;
										text-align: center;
										color: $colorGrey;
										font-size: $fontSize16;
										word-break: break-word;
									}
									.manageSec {
										display: flex;
										flex-direction: row;
										align-items: center;
										bottom: 0;
										margin-top: 10px;
										.tooltipSmall {
											padding: 10px 14px;
											background: $bglightBlue;
											margin-right: 15px;
											border-radius: 12px;
										}
										a {
											// padding: 10px 14px;
											// background: $bglightBlue;
											// border-radius: 12px;
											// margin-right: 15px;
											position: relative;
											i {
												color: $colorGrey;
											}
										}
										.toggle {
											padding-top: 20px;

											.switch {
												position: relative;
												display: inline-block;
												width: 45px;
												height: 20px;
												background-color: #99adc4;
												border-radius: 20px;
												transition: all 0.3s;
												cursor: pointer;
											}
											.switch::after {
												content: "";
												position: absolute;
												width: 32px;
												height: 32px;
												border-radius: 50%;
												background-color: $lightToggle;
												top: -6px;
												left: 0px;
												transition: all 0.3s;
											}

											.checkbox:checked + .switch::after {
												background-color: $bgYellow;
												left: 16px;
											}
											.checkbox:checked + .switch {
												background-color: $colorLightYellow;
											}
											.checkbox {
												display: none;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
	.clubslistingPage {
		.centerSec {
			.rightMain {
				.searchSec {
					padding: 12px 20px 12px 18px;
					.leftSec {
						.blackdarkTxt {
							padding-right: 15px;
						}
						.divider {
							margin-right: 15x;
						}
						.greyTxt {
							padding-right: 20px;
						}
						.searchMain {
							width: 260px;
						}
					}
				}
				.clublisting {
					padding: 20px 5px 10px 20px;
				}
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.clubslistingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 170px) !important;
				.clublisting {
					padding: 20px 5px 10px 20px;
					.clubsScroll {
						height: calc(100vh - 350px) !important;

						ul {
							li {
								.card {
									margin: 0 12px 12px 0;
									padding: 14px 14px;
									align-items: center;
									width: 185px;
									.bottomSec {
										.manageSec {
											.tooltipSmall {
												padding: 8px 12px;
											}
											.toggle {
												padding-top: 18px;
												.switch {
													width: 40px;
													height: 16px;
												}
												.switch::after {
													width: 26px;
													height: 26px;
													top: -5px;
													left: 0px;
												}

												.checkbox:checked + .switch::after {
													left: 18px;
												}
											}
										}
									}
								}
								&:nth-child(5n) {
									.card {
										margin: 0 0px 12px 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and(min-width: 768px) and (max-width: 992px) {
	.clubslistingPage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 140px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					.leftSec {
						.blackdarkTxt {
							padding-right: 10px;
						}
						.divider {
							margin-right: 10px;
						}
						.greyTxt {
							padding-right: 10px;
						}
						.searchMain {
							width: 200px;
						}
					}
				}
				.clublisting {
					padding: 20px 0px 10px 10px;
					.clubsScroll {
						height: calc(100vh - 300px) !important;

						ul {
							li {
								.card {
									margin: 0 12px 12px 0;
									padding: 14px 14px;
									align-items: center;
									width: 175px;
									.bottomSec {
										.manageSec {
											.tooltipSmall {
												padding: 8px 12px;
											}
											.toggle {
												padding-top: 18px;
												.switch {
													width: 40px;
													height: 16px;
												}
												.switch::after {
													width: 26px;
													height: 26px;
													top: -5px;
													left: 0px;
												}

												.checkbox:checked + .switch::after {
													left: 18px;
												}
											}
										}
									}
								}
								&:nth-child(4n) {
									.card {
										margin: 0 0px 12px 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 767px) {
	.clubslistingPage {
		height: 100vh;
		.centerSec {
			.rightMain {
				height: calc(100vh - 210px) !important;
				.searchSec {
					padding: 12px 20px 12px 18px;
					.leftSec {
						.blackdarkTxt {
							padding-right: 10px;
						}
						.divider {
							margin-right: 10px;
						}
						.greyTxt {
							padding-right: 10px;
						}
						.searchMain {
							width: 200px;
						}
					}
				}
				.clublisting {
					padding: 20px 5px 10px 10px;
					.clubsScroll {
						height: calc(100vh - 300px) !important;
						ul {
							li {
								.card {
									margin: 0 12px 12px 0;
									padding: 14px 14px;
									align-items: center;
									width: 190px;
									.bottomSec {
										.manageSec {
											.tooltipSmall {
												padding: 8px 12px;
											}
											.toggle {
												padding-top: 18px;
												.switch {
													width: 40px;
													height: 16px;
												}
												.switch::after {
													width: 26px;
													height: 26px;
													top: -5px;
													left: 0px;
												}

												.checkbox:checked + .switch::after {
													left: 18px;
												}
											}
										}
									}
								}
								&:nth-child(3n) {
									.card {
										margin: 0 0px 12px 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
