@import "./assets/scss/variables.scss";

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  //   "Droid Sans", "Helvetica Neue", sans-serif;
  font-family: $primaryFont !important;
  overflow: hidden;
  background-color: $bgWhite !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../src/assets/fonts/SFUIText-Light.woff2") format("woff2"),
    url("../src/assets/fonts/SFUIText-Light.woff") format("woff"),
    url("../src/assets/fonts/SFUIText-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../src/assets/fonts/SFUIText-Semibold.woff2") format("woff2"),
    url("../src/assets/fonts/SFUIText-Semibold.woff") format("woff"),
    url("../src/assets/fonts/SFUIText-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../src/assets/fonts/SFUIText-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/SFUIText-Bold.woff") format("woff"),
    url("../src/assets/fonts/SFUIText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../src/assets/fonts/SFUIText-Heavy.woff2") format("woff2"),
    url("../src/assets/fonts/SFUIText-Heavy.woff") format("woff"),
    url("../src/assets/fonts/SFUIText-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../src/assets/fonts/SFUIText-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/SFUIText-Regular.woff") format("woff"),
    url("../src/assets/fonts/SFUIText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rockwell";
  src: url("../src/assets/fonts/Rockwell-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/Rockwell-Bold.woff") format("woff"),
    url("../src/assets/fonts/Rockwell-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rockwell";
  src: url("../src/assets/fonts/Rockwell.woff2") format("woff2"),
    url("../src/assets/fonts/Rockwell.woff") format("woff"), url("../src/assets/fonts/Rockwell.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../src/assets/fonts/SFUIText-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/SFUIText-Medium.woff") format("woff"),
    url("../src/assets/fonts/SFUIText-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "greystone";
  src: url("../src/assets/fonts/greystone.eot?shbu5m");
  src: url("../src/assets/fonts/greystone.eot?shbu5m#iefix") format("embedded-opentype"),
    url("../src/assets/fonts/greystone.ttf?shbu5m") format("truetype"),
    url("../src/assets/fonts/greystone.woff?shbu5m") format("woff"),
    url("../src/assets/fonts/greystone.svg?shbu5m#greystone") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "greystone" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-article:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-dashboard:before {
  content: "\e903";
}
.icon-delete:before {
  content: "\e904";
}
.icon-down-arrow:before {
  content: "\e905";
}
.icon-edit:before {
  content: "\e906";
}
.icon-email:before {
  content: "\e907";
}
.icon-alert:before {
  content: "\e908";
}
.icon-events:before {
  content: "\e909";
}
.icon-hide-password:before {
  content: "\e90a";
}
.icon-image:before {
  content: "\e90b";
}
.icon-league:before {
  content: "\e90c";
}
.icon-left-arrow:before {
  content: "\e90d";
}
.icon-no-display:before {
  content: "\e90e";
}
.icon-notification:before {
  content: "\e90f";
}
.icon-pluse:before {
  content: "\e910";
}
.icon-radio-btn:before {
  content: "\e911";
}
.icon-right-arrow:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-select:before {
  content: "\e914";
}
.icon-selected-radio:before {
  content: "\e915";
}
.icon-share:before {
  content: "\e916";
}
.icon-show-password:before {
  content: "\e917";
}
.icon-sponsers:before {
  content: "\e918";
}
.icon-more:before {
  content: "\e919";
}
.icon-checked:before {
  content: "\e91a";
}
.icon-un-checked:before {
  content: "\e91b";
}
.icon-up-arrow:before {
  content: "\e91c";
}
.icon-upload-image:before {
  content: "\e91d";
}
.icon-user:before {
  content: "\e91e";
}
.icon-valid-message:before {
  content: "\e91f";
}
.icon-long-arrow:before {
  content: "\e920";
}
.icon-more_outline:before {
  content: "\e922";
}
.icon-clock:before {
  content: "\e923";
}
.icon-close_round:before {
  content: "\e924";
}
.icon-contact_diary:before {
  content: "\e925";
}
.icon-edit_pencil:before {
  content: "\e926";
}
.icon-eye_notvisible:before {
  content: "\e927";
}
.icon-eye_visible:before {
  content: "\e928";
}
.icon-filled_hand:before {
  content: "\e92a";
}
.icon-filled_home:before {
  content: "\e92b";
}
.icon-filled_location:before {
  content: "\e92c";
}
.icon-filled_mail:before {
  content: "\e92d";
}
.icon-filled_user:before {
  content: "\e92e";
}
.icon-Mobile:before {
  content: "\e92f";
}
.icon-outline_person:before {
  content: "\e930";
}
.icon-filled_document:before {
  content: "\e921";
}
.icon-filled_calander:before {
  content: "\e929";
}
.icon-addUser:before {
  content: "\e931";
}
.icon-DeleteNew:before {
  content: "\e932";
}
.icon-DownloadNew:before {
  content: "\e933";
}
.icon-electrone:before {
  content: "\e934";
}
.icon-imageThumb:before {
  content: "\e935";
}
.icon-imageUpload:before {
  content: "\e936";
}
.icon-linkAdd:before {
  content: "\e937";
}
.icon-MessageNew:before {
  content: "\e938";
}
.icon-protection:before {
  content: "\e939";
}

.react-slideshow-container {
  display: flex;
  align-items: center;
}
.react-slideshow-container .nav {
  z-index: 10;
}
.react-slideshow-container .default-nav {
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  width: 30px;
  border: 0;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-slideshow-container .default-nav span {
  display: block;
  width: 10px;
  height: 10px;
  border-color: #000;
  border-style: solid;
  transform: rotate(45deg);
}
.react-slideshow-container .default-nav.disabled span {
  border-color: #666;
}
.react-slideshow-container .default-nav:hover,
.react-slideshow-container .default-nav:focus {
  background: #fff;
  color: #666;
  outline: 0;
}
.react-slideshow-container .default-nav.disabled:hover {
  cursor: not-allowed;
}
.react-slideshow-container .default-nav:first-of-type {
  margin-right: -30px;
  border-right: 0;
  border-top: 0;
}
.react-slideshow-container .default-nav:first-of-type span {
  margin-left: 5px;
  border-right: 0;
  border-top: 0;
}
.react-slideshow-container .default-nav:last-of-type {
  margin-left: -30px;
}
.react-slideshow-container .default-nav:last-of-type span {
  margin-right: 5px;
  border-left: 0;
  border-bottom: 0;
}
.react-slideshow-container + div.indicators {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.react-slideshow-container + div.indicators li {
  display: inline-block;
  position: relative;
  width: 7px;
  height: 7px;
  padding: 5px;
  margin: 0;
}
.react-slideshow-container + div.indicators .each-slideshow-indicator {
  border: 0;
  opacity: 0.25;
  cursor: pointer;
  background: transparent;
  color: transparent;
}
.react-slideshow-container + div.indicators .each-slideshow-indicator:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  content: "";
  background: #000;
  text-align: center;
}
.react-slideshow-container + div.indicators .each-slideshow-indicator:hover,
.react-slideshow-container + div.indicators .each-slideshow-indicator.active,
.react-slideshow-container + div.indicators .each-slideshow-indicator:focus {
  opacity: 0.75;
  outline: 0;
}
.react-slideshow-fade-wrapper {
  width: 100%;
  overflow: hidden;
}
.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
  display: flex;
  flex-wrap: wrap;
}
.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap > div {
  position: relative;
  opacity: 0;
}
.react-slideshow-wrapper.slide {
  width: 100%;
  overflow: hidden;
}
.react-slideshow-wrapper .images-wrap {
  display: flex;
  flex-wrap: wrap;
}
.react-slideshow-zoom-wrapper {
  width: 100%;
  overflow: hidden;
}
.react-slideshow-zoom-wrapper .zoom-wrapper {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.react-slideshow-zoom-wrapper .zoom-wrapper > div {
  position: relative;
  display: flex;
}

.rightMain {
  padding-left: 60px;
  transition: padding-left 0.5s ease;
  &.sidePadding {
    padding-left: 330px;
    transition: padding-left 0.5s ease;
  }
}
@media only screen and (max-width: 1024px) {
  .rightMain {
    padding-left: 0px !important;
  }
  // .footerArea {
  //   padding-left: 20px !important;
  // }
  .simpleFooter,
  .footersidePadding {
    padding-left: 0px !important;
    .footerArea {
      padding-left: 10px;
    }
  }
}

.footersidePadding {
  padding-left: 330px;
  transition: padding-left 0.5s ease;
}
.simpleFooter {
  padding-left: 60px;
  transition: padding-left 0.5s ease;
}
:focus {
  outline: none;
}
a {
  cursor: pointer;
}

//  Tooltip css
// .css-f5brtt-BaseToolTop,
// .css-5itiqr-BaseToolTop {
//   .css-2zis3v,
//   .css-2zis3v {
//     background: $colorGrey;
//     opacity: 0.6;
//     padding: 10px 14px;
//     border-radius: 10px;
//     border: none;
//     font-size: $fontSize13;
//     .css-12sk1qt-BaseArrow,
//     .css-f5s673-BaseArrow {
//       background: $colorGrey;
//       border-left: 1px solid $colorGrey;
//       border-bottom: 1px solid $colorGrey;
//     }
//   }
// }

// .css-1meh2x2-BaseToolTop {
//   .css-2zis3v {
//     background: $colorGrey;
//     opacity: 0.6;
//     width: 160px;
//     padding: 10px 12px;
//     border-radius: 10px;
//     border: none;
//     font-size: $fontSize13;
//     .css-14cxsbx-BaseArrow {
//       background: $colorGrey;
//       border-left: 1px solid $colorGrey;
//       border-top: 1px solid $colorGrey;
//     }
//   }
// }
.tooltipSmall > div > div {
  background: rgba(153, 173, 196, 0.4);
  padding: 10px 14px;
  border-radius: 10px;
  border: none;
  font-size: $fontSize14;
}
.tooltipSmall > div > div > div {
  background: rgba(153, 173, 196, 0.4);
  border-color: rgba(153, 173, 196, 0.4);
}
.tooltipBox {
  width: 100%;
  min-width: 100% !important;
  display: flex !important;
}
.tooltipBox > div {
  margin-left: 6%;
  z-index: 99999;
  width: 200px;
  display: table;
}
.tooltipBox > div > div {
  background: rgba(153, 173, 196, 0.4);
  padding: 10px 10px;
  border-radius: 10px;
  border: none;
  font-size: $fontSize14;
  display: table;
  min-width: 60px;
  max-width: 180px;
}
.tooltipBox > div > div > div {
  background: rgba(153, 173, 196, 0.4);
  border-color: rgba(153, 173, 196, 0.4);
  border-left: 1px solid rgba(153, 173, 196, 0.4);
  border-bottom: 1px solid rgba(153, 173, 196, 0.4);
}

.tooltipBig > div > div {
  background: rgba(153, 173, 196, 0.4);
  width: 160px;
  border-radius: 10px;
  border: none;
  font-size: $fontSize14;
}
.tooltipBig > div > div > div {
  background: rgba(153, 173, 196, 0.4);
  border-color: rgba(153, 173, 196, 0.4);
}

@media (min-width: 993px) and (max-width: 1024px) {
  .tooltipBox > div {
    margin-left: 10%;
  }
}
@media (min-width: 1025px) and (max-width: 1600px) {
  .tooltipBox > div {
    margin-left: 8%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .tooltipBox > div {
    margin-left: 8%;
  }
}
