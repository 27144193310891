@import "../../assets/scss/variables.scss";
.ViewProfilePage {
	width: 100%;
	position: relative;
	float: left;
	height: 100vh;
	overflow: hidden;
	.centerSec {
		width: 100%;
		float: left;
		padding-top: 60px;
		background: $bglightBlue;
		height: 100%;
		.rightMain {
			float: left;
			height: calc(100vh - 140px) !important;
			width: 100%;
			.searchSec {
				background: $bgWhite;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 12px 40px 12px 32px;
				.blackdarkTxt {
					color: $colorDarkBlue;
					font-size: $fontSize18;
					padding-right: 25px;
					font-weight: 500;
				}
			}
			.ViewProfile {
				height: calc(100vh - 200px) !important;
				.whiteBox {
					display: flex;
					flex-direction: row;
					height: 91%;
					background: $bgWhite;
					border-radius: 8px;
					margin: 30px;
					padding: 70px 0px;

					.leftSec {
						width: 50%;
						display: flex;
						flex-direction: column;
						align-items: center;
						padding: 28px 0px;
						.imgSec {
							height: 130px;
							margin: 0 0 22px 0;
							img {
								height: 100%;
								width: 100%;
								object-fit: contain;
							}
						}
						.blueDarkTxt {
							color: $colorDarkBlue;
							font-size: $fontSize24;
						}
					}
					.rightSec {
						width: 50%;
						border-left: 1px solid $colorBorderBlue;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						padding: 28px 0px;
						.textSec {
							display: flex;
							align-items: flex-start;
							flex-direction: column;

							width: 70%;
							padding-left: 10%;
							.infoView {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								margin-bottom: 70px;
								.labelTxt {
									font-size: $fontSize14;
									color: $colorGrey;
								}
								.infoTxt {
									display: flex;
									align-items: center;
									margin-top: 20px;
									i {
										color: $colorLightYellow;
										font-size: $fontSize22;
										padding-right: 16px;
									}
								}
							}
						}
						.btnSec {
							display: flex;
							justify-content: flex-start;
							align-items: flex-start;
							width: 60%;
							padding-left: 10%;
						}
					}
				}
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.ViewProfilePage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 180px) !important;
				// background: red;
				.ViewProfile {
					height: calc(100vh - 260px) !important;
				}
			}
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.ViewProfilePage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 170px) !important;
			}
		}
	}
}
@media only screen and (min-width: 993px) and (max-width: 1024px) {
	.ViewProfilePage {
		.centerSec {
			.rightMain {
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.ViewProfilePage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 150px) !important;
				.ViewProfile {
					height: calc(100vh - 240px) !important;
				}
			}
		}
	}
}
@media (min-width: 480px) and (max-width: 767px) {
	.ViewProfilePage {
		.centerSec {
			.rightMain {
				height: calc(100vh - 120px) !important;
				.ViewProfile {
					height: calc(100vh - 170px) !important;

					.whiteBox {
						flex-direction: column;
						height: 91%;
						padding: 20px 0px;

						.leftSec {
							width: 70%;
							display: flex;
							flex-direction: column;
							padding-left: 10%;
							align-items: flex-start;

							.imgSec {
								height: 130px;
								margin: 0 0 22px 0;
								img {
									height: 100%;
									object-fit: contain;
								}
							}
							.blueDarkTxt {
								color: $colorDarkBlue;
								font-size: $fontSize24;
							}
						}
						.rightSec {
							width: 70%;
							border: none;
							padding: 30px 0px;
							padding-left: 10%;
							height: 100%;

							.textSec {
								display: flex;
								align-items: flex-start;
								flex-direction: column;
								padding: 0;
								width: 70%;

								.infoView {
									margin-bottom: 50px;
								}
							}
							.btnSec {
								display: flex;
								align-items: flex-start;
								width: 70%;
								padding-left: 0;
							}
						}
					}
				}
			}
		}
	}
}
