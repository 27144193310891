@import "../../assets/scss/variables.scss";
.SponsersMain {
  position: relative;
  display: flex;
  flex-direction: row;
  .modalOuterScroll {
    height: calc(100vh - 200px) !important;
    display: flex;
    form {
      display: flex;
      width: 100%;
      .modalmainView {
        display: flex;
        width: 100%;
        .leftSec {
          width: 50%;
          margin: 20px 0px;
          // padding-left: 30px;
          .leftSideScroll {
            .partiton {
              width: 100%;
              position: relative;
              .mainsubtitle {
                font-size: 18px;
                color: #293d4e;
                font-weight: 500;
                margin-bottom: 5px;
                background: white;
                padding: 0px 4px;
                position: absolute;
                top: -12px;
                left: 18px;
              }
              .bothRecSec {
                display: flex;
                flex-direction: column;

                &.border {
                  margin: 0px 10px 40px 0;
                  padding: 12px 20px 10px 20px;
                  border-radius: 14px;
                }
                &.dropdownWithSec {
                  padding-right: 20px;
                  padding-top: 28px;
                }
                .dualBox {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  margin-top: 10px;
                  &.hide {
                    display: none;
                  }
                  .rectangleBox {
                    width: 48%;
                    .boxTitle {
                      font-size: $fontSize13;
                      color: $colorGrey;
                      margin-bottom: 10px;
                      .redStar {
                        color: #f64e60;
                      }
                    }
                    .touchBox {
                      height: 100%;
                      width: 100%;
                      display: flex;
                      .boxZoneImgView {
                        position: relative;
                        width: 100%;
                        background: #e9edef;
                        border-radius: 8px;
                        overflow: hidden;
                        img {
                          height: 100% !important;
                          width: 100% !important;
                          // object-fit: contain;
                        }
                        .closeIcon {
                          position: absolute;
                          top: 1px;
                          right: 2px;
                          background: $bglightBlue;
                          border-radius: 15px;
                          height: 22px;
                          width: 22px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px !important;
                          z-index: 999;
                          i {
                            color: $colorDarkBlue;
                            font-size: $fontSize14;
                          }
                        }
                      }

                      .uploadBox {
                        height: 100%;
                        width: 100%;
                        border: 1px dashed $colorBorderBlue;
                        border-radius: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        // margin-right: 5px;
                        background: #e9edef;
                        i {
                          color: $colorGrey;
                          font-size: $fontSize20;
                        }
                        a {
                          color: $colorGrey;
                          font-size: $fontSize16;
                          padding-top: 15px;
                        }
                      }
                    }
                    .tabletUploadArea {
                      margin: 2px 0 15px 0;
                      .allBoxView {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        height: 100%;
                        width: 100%;
                        cursor: pointer;
                        .imgViewBox {
                          height: 90px;
                          width: 90px;
                          margin-right: 10px;
                          margin-bottom: 30px;
                          position: relative;
                          img {
                            object-fit: contain;
                            width: 100%;
                            height: 100%;
                            border-radius: 12px;
                          }
                          .bottomMobileIcon {
                            i {
                              padding-top: 5px;
                              color: $colorGrey;
                              font-size: $fontSize16;
                            }
                          }
                          .numberSize {
                            // width: 32px;
                            height: 14px;
                            border-radius: 6px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            right: 5px;
                            top: 5px;
                            background: $bglightred;
                            color: $colorWhite;
                            font-size: $fontSize10;
                            padding: 4px;
                            z-index: 99;
                          }
                          .overlay {
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            background: $bgBlack;
                            opacity: 0.1;
                            top: 0;
                            border-radius: 12px;
                            z-index: 9;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .labelImageView {
              margin-bottom: 40px;
              .labelsmallTxt {
                font-size: $fontSize18;
                color: $colorDarkBlue;
                font-weight: 500;
                margin: 0;
                margin-bottom: -5px;
                .redStar {
                  color: $colorLightRed;
                  padding-left: 3px;
                }
              }
              &.border {
                margin: 0px 10px 40px 0;
                padding: 15px 0 0 20px;
                border-radius: 14px;
              }
            }
            .bannersSec,
            .labelImageView {
              display: block;
              // width: 88%;
              &.border {
                margin: 0px 10px 40px 0;
                padding: 18px 18px 0 20px;
                border-radius: 14px;
              }
              label {
                font-size: $fontSize13;
                color: $colorGrey;
                margin: 0;
                .redStar {
                  color: $colorLightRed;
                }
              }
              .bannerScroll {
                // height: calc(100vh - 360px) !important;
                .bannerOuter {
                  display: block;

                  .bannerImgRow {
                    background: $bgLightGrey;
                    padding: 16px 20px;
                    display: flex;
                    justify-content: space-between;
                    border-radius: 12px;
                    align-items: center;
                    margin-bottom: 18px;
                    .iconRealImg {
                      height: 50px;
                      width: 60px;
                      margin-right: 10px;
                      .bannerImg {
                        border-radius: 8px;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                      }
                    }
                    .iconImg {
                      width: 12%;
                      margin-right: 10px;
                      .yellowIcon {
                        background: $bgYellow;
                        border-radius: 8px;
                        height: 24px;
                        width: 28px;
                        display: block;
                        position: relative;
                        &::before {
                          position: absolute;
                          content: "";
                          bottom: 5px;
                          left: 4px;
                          width: 0;
                          height: 0;
                          border-left: 7px solid transparent;
                          border-right: 7px solid transparent;
                          border-bottom: 7px solid $colorWhite;
                        }
                        &::after {
                          position: absolute;
                          content: "";
                          top: 5px;
                          right: 4px;
                          background: $bgWhite;
                          border-radius: 50%;
                          width: 8px;
                          height: 8px;
                        }
                      }
                    }
                    .imgInfo {
                      width: 80%;
                      .topSec {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 5px;
                        .blackTxt {
                          font-size: $fontSize16;
                          color: $colorDarkBlue;
                        }
                        i {
                          font-size: $fontSize16;
                          color: $colorGrey;
                          cursor: pointer;
                        }
                      }
                      .bottomSec {
                        .custom {
                          height: 8px;
                        }
                      }
                      &.infoTxt {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .topSec {
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                          padding: 0;
                          width: 88%;
                          .blackTxt {
                            font-size: $fontSize16;
                            color: $colorDarkBlue;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 100%;
                          }
                          .greyTxt {
                            font-size: $fontSize16;
                            color: $colorGrey;
                          }
                        }
                        .bottomSec {
                          i {
                            font-size: $fontSize16;
                            color: $colorGrey;
                            cursor: pointer;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .rightSec {
          width: 50%;
          display: flex;
          // align-items: center;
          justify-content: center;
          border-left: 1px solid $bgLightGrey;
          .formSec {
            margin: 15px 0;
            display: flex;
            flex-direction: column;
            width: 85%;
            .textField {
              margin-bottom: 40px;
              .form-group {
                .errorMsg {
                  position: absolute;
                }
              }
            }
            .countryPhoneGroup ~ .errorMsg {
              left: 25%;
            }
            .labelSec {
              display: inline;
              display: flex;
              .labelTxt {
                font-size: $fontSize13;
                color: $colorGrey;
                margin-bottom: 15px;
                display: inline-block;
              }
              .redStar {
                color: $colorLightRed;
                padding-left: 2px;
              }
            }
            .linkSec {
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              justify-content: space-between;

              .linkField {
                width: 100%;
                display: flex;
                flex-direction: column;
                .plusView {
                  display: flex;
                  flex-direction: row;
                  .textField {
                    margin-bottom: 30px;
                    .form-group {
                      .inputBox {
                        input {
                          padding: 7px 18px 8px 16px;
                        }
                      }
                    }
                  }
                  .addLinkBtn {
                    display: flex;
                    flex-direction: row;
                    padding-left: 8px;
                    margin-bottom: 34px;
                    // padding-bottom: 34px;
                    // padding-top: 34px;
                    .closeIcon {
                      padding: 12px 5px;
                      border-radius: 15px;
                      background: $bglightred;
                      width: 55px;
                      i {
                        color: $colorWhite;
                        font-size: $fontSize26;
                      }
                    }
                    .plusIcon {
                      padding: 12px 5px;
                      border-radius: 15px;
                      background: $bgYellow;
                      width: 55px;
                      margin: 0px 0 0 6px;
                      i {
                        color: $colorWhite;
                        font-size: $fontSize26;
                      }
                    }
                  }
                }
                .cancelView {
                  display: flex;
                  flex-direction: row;
                  .textField {
                    margin-bottom: 30px;
                    .form-group {
                      .inputBox {
                        input {
                          padding: 7px 18px 8px 16px;
                        }
                      }
                    }
                  }
                  .singleIcon {
                    display: flex;
                    align-self: flex-end;
                    margin-bottom: 34px;
                    padding-left: 8px;
                    .plusIcon {
                      padding: 12px 5px;
                      border-radius: 15px;
                      background: $bgYellow;
                      width: 55px;
                      margin: 0px 0 0 6px;
                      &.disabled {
                        background: $bgGrey;
                      }
                      i {
                        color: $colorWhite;
                        font-size: $fontSize26;
                      }
                    }
                    .closeIcon {
                      padding: 12px 5px;
                      border-radius: 15px;
                      background: $bglightred;
                      width: 55px;
                      i {
                        color: $colorWhite;
                        font-size: $fontSize26;
                      }
                    }
                  }
                }

                .textField {
                  margin-bottom: 30px;
                  .form-group {
                    .inputBox {
                      input {
                        padding: 7px 18px 8px 16px;
                      }
                    }
                  }
                }
              }
              .btnGroupPart {
                display: flex;
                flex-direction: column-reverse;
              }
            }
            .socialSec {
              margin-top: 25px;
              display: block;
              label {
                font-size: $fontSize18;
                color: $colorDarkBlue;
                font-weight: 500;
              }
              .textField {
                margin-bottom: 30px;
                .form-group {
                  .inputBox {
                    input {
                      padding: 7px 8px 8px 58px;
                    }
                  }
                }
              }
            }
            .btnGroup {
              display: flex;
              justify-content: flex-end;
              margin-top: 20px;
              .submitBtn {
                width: 35%;
                height: 40px;
                // padding: 8px 42px;
              }
              .cancelBtn {
                width: 35%;
                height: 40px;
                // padding: 8px 28px;
                margin-left: 30px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  .SponsersMain {
    .modalOuterScroll {
      form {
        .modalmainView {
          .leftSec {
            padding-left: 10px;
            .labelImageView {
              width: 95%;
            }
            .bannersSec {
              width: 95%;
            }
          }
          .rightSec {
            .formSec {
              margin: 10px 0;
              display: flex;
              flex-direction: column;
              width: 90%;
              .textField {
                margin-bottom: 30px;
              }
              .linkSec {
                .addLinkBtn {
                  .plusIcon {
                    width: 50px;
                  }
                  .closeIcon {
                    width: 50px;
                  }
                }
              }
              .btnGroup {
                .submitBtn {
                  width: 45%;
                  height: 40px;
                }
                .cancelBtn {
                  width: 45%;
                  height: 40px;
                  margin-left: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .SponsersMain {
    .modalOuterScroll {
      form {
        width: 100%;
        .modalmainView {
          width: 100%;
          flex-direction: column;
          .leftSec {
            // width: 50%;
            // padding-left: 10px;
            width: 100%;
            margin: 20px 0 0 0;
            .labelImageView {
              width: 100%;
              .bannerScroll {
                .bannerOuter {
                  .bannerImgRow {
                    .iconRealImg {
                      width: 60px;
                    }
                    .imgInfo {
                      &.infoTxt {
                        width: 75%;
                      }
                    }
                  }
                }
              }
            }
            .bannersSec {
              width: 100%;
              .bannerLongView {
                .fullWidthUploadArea {
                  // padding-right: 25px;
                }
              }
              .bannerScroll {
                // height: calc(100vh - 440px) !important;
                .bannerOuter {
                  padding-right: 25px;
                }
              }
            }
          }
          .rightSec {
            align-items: flex-start;
            width: 100%;
            border: none;
            .formSec {
              margin: 10px 0;
              display: flex;
              flex-direction: column;
              width: 99%;
              .textField {
                margin-bottom: 30px;
                .form-group {
                  .inputBox {
                    .countryPhoneGroup {
                      .react-tel-input {
                        width: 160px;
                        .flag-dropdown {
                          .selected-flag {
                            &::before {
                              left: 10px;
                            }
                            .flag {
                              .arrow {
                                left: 90px;
                              }
                            }
                          }
                        }
                      }
                      input {
                        width: 82%;
                      }
                    }
                  }
                }
                .countryPhoneGroup ~ .errorMsg {
                  left: 145px;
                }
              }
              .btnGroup {
                justify-content: space-between;
                .submitBtn {
                  width: 45%;
                  height: 40px;
                }
                .cancelBtn {
                  width: 45%;
                  height: 40px;
                  margin-left: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .SponsersMain {
    .modalOuterScroll {
      form {
        display: flex;
        width: 100%;
        .modalmainView {
          flex-direction: column;

          .leftSec {
            width: 100%;
            margin: 0px 0px;
            padding-left: 0px;
            .leftSideScroll {
              // height: 1250px !important;
              .bannersSec,
              .labelImageView {
                width: 100%;
                .bannerLongView {
                  .fullWidthUploadArea {
                    // padding-right: 10px;
                  }
                }
                .bannerScroll {
                  // height: calc(100vh - 1100px) !important;
                  // .bannerOuter {
                  //   display: none;
                  // }
                  .bannerOuter {
                    padding-right: 10px;
                  }
                }
              }
            }
          }

          .rightSec {
            width: 100%;
            border: none;
            form {
              width: 100%;
            }
            .formSec {
              margin: 10px 0;
              display: flex;
              flex-direction: column;
              width: 100%;
              .textField {
                margin-bottom: 30px;
                .form-group {
                  .inputBox {
                    .countryPhoneGroup {
                      .react-tel-input {
                        width: 24%;
                        // .flag-dropdown {
                        //   .selected-flag {
                        //     .flag {
                        //       .arrow {
                        //         left: 65px;
                        //       }
                        //     }
                        //   }
                        // }
                      }
                      input {
                        width: 82%;
                      }
                    }
                  }
                }
                .countryPhoneGroup ~ .errorMsg {
                  left: 23%;
                }
              }
              .btnGroup {
                .submitBtn {
                  width: 30%;
                  height: 40px;
                }
                .cancelBtn {
                  width: 30%;
                  height: 40px;
                  margin-left: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
